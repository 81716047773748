import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  OutlinedInput,
  Typography,
  FormLabel,
  FormGroup,
  Modal,
  TextField,
  Radio,
  Tooltip,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  getDepartmentData,
  getSingleUser,
  getUserData,
  postUserData,
  updateData,
} from "../redux/nbmData/action";
import AddUserModal from "./AddUserModal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { all } from "axios";
import EditUserModal from "./EditUserModal";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { updateSubuserToSuperAdmin } from "../redux/data/action";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",
  color: "#1746A2",
  fontSize: "20px",
  fontWeight: 400,
  [theme.breakpoints.down("xl")]: {
    fontSize: "16px",
    fontWeight: 400,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5px",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const Boxes = styled(Box)(({ theme }) => ({
  border: "none",
  width: "370px",

  [theme.breakpoints.down("xl")]: {
    width: "370px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
}));
const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "80px",

  justifyContent: "center",
  [theme.breakpoints.up("xl")]: {
    flexDirection: "row",
    marginTop: "20px",
    gap: "50px",
  },
  [theme.breakpoints.down("xl")]: {
    flexDirection: "row",
    marginTop: "20px",
    gap: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    flexDirection: "row",
    gap: "20px",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "20px",
    marginTop: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "20px",
    marginTop: "20px",
  },
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    gap: "10px",
  },
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  bgcolor: "white",
  minHeight: "510px",

  padding: "20px",

  borderRadius: "15px",
  display: "flex",
  width: "950px",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    width: "850px",
    minHeight: "410px",
    // height: "505px",

    bgcolor: "white",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
    // height: "505px",
    // border: "1px solid blue",
  },
  [theme.breakpoints.down("md")]: {
    // height: "805px",
  },
  [theme.breakpoints.down("sm")]: {
    // height: "750px",
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    // height: "710px",
  },
}));

const LowerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "1030px",
  justifyContent: "center",
  gap: "30px",
  marginTop: "20px",

  [theme.breakpoints.down("xl")]: {
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",

    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column-reverse",
    gap: "10px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    justifyContent: "right",
    alignItems: "center",
    gap: "10px",
  },
}));

const UpperContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  width: "950px",
  justifyContent: "left",
  gap: "20px",
  paddingTop: "10px",
  paddingBottom: "10px",
  [theme.breakpoints.down("xl")]: {
    width: "850px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "90%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

const FormInput = styled(TextField)(({ theme }) => ({
  border: "none",
  width: "370px",

  [theme.breakpoints.down("xl")]: {
    width: "370px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
}));

const Main = styled(Box)(({ theme }) => ({
  display: "flex",

  width: "100%",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

function EditPage() {


  const allOption = {
    departid: "all",
    departname: "All",
  };

  
  const departmentData = useSelector((store) => store.nbmData.department);
  const superAdminUpdate = useSelector((store) => store.nbmData.updateSuperAdmin);
  const dataEdit = useSelector((store) => store.nbmData.singleUser);
  const update = useSelector((store) => store.nbmData.updateData);
  const params = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const userData =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));

  const [toggle, setToggle] = useState(false);
  const [superAdminToggle, setSuperAdminToggle] = useState(false);
  const [selectAll, setSelectAll] = React.useState(false);

  const [department, setDepartment] = useState("");
  const [allData, setAllData] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");

  const [userRoll, setUserRoll] = useState("");
  const [flag, setFlag] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  // const [selectedDepartments, setSelectedDepartments] = React.useState([]);
  const [selectedDepartments, setSelectedDepartments] = React.useState(
    dataEdit.departments
      ?.map((deptName) =>
        departmentData.find((dept) => dept.departname === deptName)
      )
      .filter(Boolean)
  );
  const [fullColor, setFullColor] = useState("black");
  const [viewColor, setViewColor] = useState("black");
  const [partialColor, setPartialColor] = useState("black");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [userRollError, setUserRollError] = useState("");
  const [emailError, setEmailError] = useState("");


  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const handleChange = (_, value) => {





    if (value.some((dept) => dept.departid === "all")) {
      setSelectAll(true);
      setSelectedDepartments([allOption]);
    } else {
      setSelectAll(false);
      setSelectedDepartments(value.filter((dept) => dept.departid !== "all"));
     
    }
  };

  const handleDesignation = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9\s-]+$/;

    if (value === "" || regex.test(value)) {
      setDesignation(value);
      setDesignationError("");
    }
    if (value !== "" && !regex.test(value)) {
      setDesignationError("Invalid designation");
    }
    if (value === "") {
      setDesignationError("Please fill the field");
    }
  };

  const handleNumber = (e) => {
    const value = e.target.value.trim();
    const regex = /^\d{10}$/;
    setMobileNumber(value);
    if (value === "" || regex.test(value)) {
      setMobileNumber(value);
      setNumberError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNumberError("Invalid number");
    }
    if (value === "") {
      setNumberError("Please fill the field");
    }
  };

  const handleName = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z\s]+$/;
    setName(value);
    if (value === "" || regex.test(value)) {
      setName(value);
      setNameError("");
    }
    if (value !== "" && !regex.test(value)) {
      setNameError("Invalid Name");
    }
    if (value === "") {
      setNameError("Please fill the field");
    }
  };

  const handleAdmin = () => {
    setFlag(!flag);
    setPartialColor("#0E4FCD");
    setFullColor("black");
    setViewColor("black");
    setUserRoll("Admin");
    setAllData((prevData) => ({
      ...prevData,
      role: "Admin",
    }));
  };

  useEffect(() => {
    let data = {
      adminid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch.id,
    };
    dispatch(getUserData(data));
  }, [dispatch]);

  useEffect(() => {
    let data = {
      adminid: userIds.userid,
      userid: params.id,
      hospid: userIds.hospid,
      branchid: userBranch?.id,
    };
    dispatch(getSingleUser(data));
  }, [dispatch]);

  const handleClick = () => {
    if (
      name !== "" &&
      email !== "" &&
      department !== "" &&
      designation !== "" &&
      userRoll !== "" &&
      nameError === "" &&
      emailError === "" &&
      designationError === "" &&
      selectedDepartments?.length !== 0 &&
      userRollError === ""
    ) {
      const formattedDepartments =
        selectedDepartments.length === 0
          ? [
              {
                depart_id: "all",
                depart_name: "All",
              },
            ]
          : selectedDepartments.map((dept) => ({
              depart_id: dept.departid,
              depart_name: dept.departname,
            }));

      let data = {
        adminid: userData.userid,
        hospid: userData.hospid,
        userid: params.id,
        username: name.trim(),
        usermobile: mobileNumber.trim(),
        userdesig: designation.trim(),
        branchname: userBranch?.branch,
        branchid: userBranch?.id,
        branchdepart: {
          branchId: userBranch?.id,
          branch: userBranch?.branch,
          role: selectedRole,
          addprod:
            selectedRole === "Branch_Admin" ||
            selectedRole === "Super_Admin" ||
            selectedRole === "Admin"
              ? "Yes"
              : "No",
          delprod:
            selectedRole === "Branch_Admin" ||
            selectedRole === "Super_Admin" ||
            selectedRole === "Admin"
              ? "Yes"
              : "No",

          editprod:
            selectedRole === "Branch_Admin" ||
            selectedRole === "Super_Admin" ||
            selectedRole === "Admin"
              ? "Yes"
              : "No",
          adddepart:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          deldepart:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          editdepart:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          adduser:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "Yes"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          deluser:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",
          edituser:
            selectedRole === "Branch_admin" || selectedRole === "Super_Admin"
              ? "Yes"
              : selectedRole === "Admin" &&
                selectedDepartments &&
                selectedDepartments.some((dept) => dept.departid !== "all")
              ? "No"
              : selectedRole === "Guest"
              ? "No"
              : "Yes",

          department: formattedDepartments,
        },
      };

      let datatoSuperAdmin = {
        admin_id: userData.userid,
        userid: params.id,
        hosp_id: userData.hospid,
        hosp_name: userData.hospname,
        user_name: name.trim(),
        user_email: email,
        user_mobile: mobileNumber.trim(),
        user_desig: designation.trim(),
      };

      if (selectedRole === "Super_Admin") {
        dispatch(updateSubuserToSuperAdmin(datatoSuperAdmin));
        setSuperAdminToggle(true);
      }

      if (selectedRole !== "Super_Admin") {
        dispatch(updateData(data));
        setToggle(true);
      }
    }

    if (name === "") {
      setNameError("Please fill the field");
    }
    if (email === "") {
      setEmailError("Please fill the field");
    }
    if (mobileNumber === "") {
      setNumberError("Please fill the field");
    }
    if (department === "") {
      setDepartmentError("Please fill the field");
    }
    if (designation === "") {
      setDesignationError("Please fill the field");
    }
    if (userRoll === "") {
      setUserRollError("Please select the role");
    }
  };

  useEffect(() => {
    let data = {
      hospid: userData.hospid,
      userid: userData.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (dataEdit && dataEdit?.length !== 0) {
      // const convertedData = convertArraysToStrings(dataEdit);
  
      setAllData(dataEdit);
      setName(dataEdit?.username);
      setEmail(dataEdit?.email);
      setMobileNumber(dataEdit?.mobile);
      setDesignation(dataEdit?.designate);
      setDepartment(dataEdit?.departments);
      setUserRoll(dataEdit?.role);
      setSelectedRole(dataEdit?.role);

      const departmentsArray = dataEdit?.departids?.map((id, index) => ({
        departid: id,
        departname: dataEdit?.departments[index],
      }));
      setSelectedDepartments(departmentsArray);

      if (dataEdit?.role === "Guest") {
        setFlag(false);
        setPartialColor("black");
        setFullColor("black");
        setViewColor("#0E4FCD");
      }
    }
  }, [dataEdit]);

  const handleCloseModal = () => {
    setToggle(false);
    setSuperAdminToggle(false);
    navigate("/user");
  };
  const handleButtonClick = (role) => {
    if (role === "Super_Admin") {
      setSelectedRole(role);
      setSelectedDepartments([{ departid: "all", departname: "All" }]);
    }

    if (role === "Branch_Admin") {
      setSelectedRole(role);
      setSelectedDepartments([{ departid: "all", departname: "All" }]);
    }

    setSelectedRole(role);
  };
 
  const handleDepartmentsChange = (event, newDepartments) => {
    // Check if "All" is selected
    const isAllSelected = newDepartments.some(
      (department) => department.departid === "all"
    );

    // If "All" is selected, deselect all other departments
    if (isAllSelected) {
      newDepartments = newDepartments.filter(
        (department) => department.departid === "all"
      );
    }

    setSelectedDepartments(newDepartments);
  };
  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
        disabled={selectAll && option.departid !== "all"}
      />

      {option?.departname}
    </li>
  );
  return (
    <Main>
    <UpperContainer>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: {
            xl: "28px",
            lg: "22px",
            md: "22px",
            sm: "20px",
            xs: "20px",
          },
          color: "#FF731D",
          textAlign: "left",
        }}>
        Edit User
      </Typography>
    </UpperContainer>
    <InnerContainer sx={{ bgcolor: "white" }}>
      <Stack>
        <FormControl>
          <FormWrapper>
            <Stack>
              <TextLabel>Name</TextLabel>
              <FormInput
                size="small"
                sx={{ bgcolor: "#EFF5FE" }}
                error={nameError}
                helperText={nameError}
                onChange={handleName}
                value={name}
                placeholder="Name"
              />
            </Stack>
            <Stack>
              <TextLabel>Email</TextLabel>
              <FormInput
                size="small"
                sx={{ bgcolor: "#EFF5FE" }}
                disabled
                onChange={(e) => setEmail(e.target.value)}
                placeholder="e.g. example@gmail.com"
                value={email}
                error={emailError}
                helperText={emailError}
                //   error

                //   helperText="Incorrect entry."
              />
            </Stack>
          </FormWrapper>
          <FormWrapper>
            <Stack>
              <TextLabel>Mobile Number</TextLabel>
              <FormInput
                size="small"
                sx={{ bgcolor: "#EFF5FE" }}
                onChange={handleNumber}
                placeholder="e.g. 8971580265"
                value={mobileNumber}
                error={numberError}
                helperText={numberError}
              />
            </Stack>
            <Stack>
              <TextLabel>Designation</TextLabel>
              <FormInput
                size="small"
                sx={{ bgcolor: "#EFF5FE" }}
                onChange={handleDesignation}
                placeholder="Designation"
                error={designationError}
                helperText={designationError}
                value={designation}
              />
            </Stack>
          </FormWrapper>

          <FormWrapper>
            <Stack
              sx={{
                width:
                  selectedRole === "Admin" || selectedRole === "Guest"
                    ? "50%"
                    : "100%",
              }}>
              <TextLabel>User access</TextLabel>
              <Box
                sx={{
                  marginTop: "2%",
                  width: "50%",
                }}>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      justifyItems: "start",
                    }}>
                    {
                      <>
                        {(userAccess?.role === "Owner" ||
                          userAccess?.role === "Super_Admin") && (
                          <div>
                            <button
                              style={{
                                backgroundColor:
                                  selectedRole === "Super_Admin"
                                    ? "#1746A2"
                                    : "white",
                                color:
                                  selectedRole === "Super_Admin"
                                    ? "white"
                                    : "#1746A2",
                                border: "1px solid #1746A2",
                                borderRadius: "10px",
                                width: "150px",
                                padding: "10px",
                                margin: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleButtonClick("Super_Admin")
                              }>
                              Super Admin
                            </button>
                          </div>
                        )}

                        {(userAccess?.role === "Owner" ||
                          userAccess?.role === "Branch_Admin" ||
                          userAccess?.role === "Super_Admin") && (
                          <div>
                            <button
                              style={{
                                backgroundColor:
                                  selectedRole === "Branch_Admin"
                                    ? "#1746A2"
                                    : "white",
                                color:
                                  selectedRole === "Branch_Admin"
                                    ? "white"
                                    : "#1746A2",
                                border: "1px solid #1746A2",
                                borderRadius: "10px",
                                width: "150px",
                                padding: "10px",
                                margin: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleButtonClick("Branch_Admin")
                              }>
                              Branch Admin
                            </button>
                          </div>
                        )}
                      </>
                    }

                    {userAccess?.role !== "Guest" && (
                      <div>
                        <button
                          style={{
                            backgroundColor:
                              selectedRole === "Admin" ? "#1746A2" : "white",
                            color:
                              selectedRole === "Admin" ? "white" : "#1746A2",
                            border: "1px solid #1746A2",
                            borderRadius: "10px",
                            width: "150px",
                            padding: "10px",

                            margin: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleButtonClick("Admin")}>
                         Department Admin
                        </button>
                      </div>
                    )}

                    <div>
                      <button
                        style={{
                          backgroundColor:
                            selectedRole === "Guest" ? "#1746A2" : "white",
                          color:
                            selectedRole === "Guest" ? "white" : "#1746A2",
                          border: "1px solid #1746A2",
                          borderRadius: "10px",
                          width: "150px",
                          padding: "10px",
                          margin: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleButtonClick("Guest")}>
                        Guest
                      </button>
                    </div>
                  </div>
                </div>
              </Box>

              <HelperText>{userRollError}</HelperText>
            </Stack>

            {selectedRole === "Admin" || selectedRole === "Guest" ? (
              <FormElement sx={{ width: "50%" }}>
                <TextLabel>Select Department</TextLabel>
                <Boxes>
                
                   <Autocomplete
                      multiple
                      id="edit-departments"
                      options={[allOption, ...departmentData]}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.departname || ""}
                      value={selectedDepartments}
                      onChange={handleDepartmentsChange}
                      renderOption={renderOption}
                      style={{
                        textAlign: "left",
                        background: "#EFF5FE",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Departments"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.departid === value.departid
                      }
                    />
                </Boxes>
                {selectedDepartments?.length === 0 && (
                  <HelperText>Select at least one department</HelperText>
                )}
              </FormElement>
            ) : null}

          
          </FormWrapper>
        </FormControl>
      </Stack>
    </InnerContainer>

    <LowerContainer>
      <Button
        variant="contained"
        sx={{
          borderRadius: "35px",
          bgcolor: "white",
          textTransform: "none",
          color: "#3c52b2",
          border: "1px solid #1746A2",
          "&:hover": {
            backgroundColor: "#fff",
            color: "#3c52b2",
          },
        }}
        onClick={() => navigate(-1)}
        size="large">
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{ borderRadius: "35px", bgcolor: "#1746A2" }}
        size="large">
        Update
      </Button>
      <EditUserModal
        status={update}
        handleCloseModal={handleCloseModal}
        toggle={toggle}
      />

      <EditUserModal
        status={superAdminUpdate}
        handleCloseModal={handleCloseModal}
        toggle={superAdminToggle}
      />
    </LowerContainer>
  </Main>
  );
}

export default EditPage;
