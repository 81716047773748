import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Avatar from "@mui/material/Avatar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useLocation, useNavigate } from "react-router-dom";
import vajra from "../images/vajra.png";
import { Link } from "react-router-dom";
import {
  getUserData,
  logOut,
  retrieveAllAssets,
  setNavigationSource,
} from "../redux/data/action";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import {
  Badge,
  Button,
  Fab,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

import LogoutIcon from "@mui/icons-material/Logout";
import PrivateRoute from "./../routes/PrivateRoute";

import { useState } from "react";
import { useEffect } from "react";

import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DashboardIcon from "@mui/icons-material/Dashboard";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import SettingsIcon from "@mui/icons-material/Settings";
import SmsIcon from "@mui/icons-material/Sms";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import CloseIcon from "@mui/icons-material/Close";
import NavbarMain from "./NavbarMain";
import PrivateFooter from "./PrivateFooter";
import PrivateNavbar from "./PrivateNavbar";

const drawerWidth = 200;

const BranchSelect = styled(Select)(({ theme }) => ({
  height: "40px",
  background: "white",
}));

const PreviousContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  justifyContent: "center",
}));
const SidebarItem = styled(ListItem)(({ $selected }) => ({
  backgroundColor: $selected ? "white" : "#1B2535",
  color: $selected ? "rgba(70, 144, 255, 1)" : "white",
  borderRadius: "22px",
  height: "33px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CustomScrollbar = styled("div")({
  height: "400px",
  maxHeight: "400px",
  overflow: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "#888 #1B2535",

  "&::-webkit-scrollbar": {
    width: "12px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#1B2535",
    position: "relative",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#1B2535",
  },
  "&::-webkit-scrollbar-track:before, &::-webkit-scrollbar-track:after": {
    content: '""',
    position: "absolute",
    left: "0",
    right: "0",
    height: "12px",
    background: "#f1f1f1",
    borderRadius: "50%",
  },
  "&::-webkit-scrollbar-track:before": {
    top: "-6px", // Adjust position for the top rounded part
  },
  "&::-webkit-scrollbar-track:after": {
    bottom: "-6px", // Adjust position for the bottom rounded part
  },
});
const IconWrapper = styled(ListItemIcon)(({ $selected }) => ({
  color: $selected ? "rgba(70, 144, 255, 1)" : "white",
  minWidth: "43px",
}));
const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  background: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  height: "330px",
}));
function ResponsiveDrawer() {
  const [toggle, setToggle] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = React.useState("");
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  console.log("data are", userIds.subs_plan);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [selectedBranch, setSelectedBranch] = useState(userBranch);
  const [nbmChecker, setNbmChecker] = useState([]);

  const [selectedItem, setSelectedItem] = useState("");

  const [heading, setHeading] = useState("");

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const truncatedUsername = userIds.username
    ? userIds.username.slice(0, 2).toUpperCase()
    : "";

  const token = localStorage.getItem("userToken");

  const path = location?.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const branchesAndIds = userIds?.bm_branches?.map((branchName, index) => ({
    branch: branchName,
    id: userIds?.bm_branchids[index],
  }));

  // if (restrictedPlans.includes(userIds?.subs_plan)) {
  //   return <Navigate to="/" />; // Redirect to home or any other safe route
  // }
  // return children;

  const handleChange = () => {
    setSelectedBranch({
      branch: userIds?.nbm_branches[0],
      id: userIds?.nbm_branchids[0],
    });

    let branchData = {
      branch: userIds?.nbm_branches[0],
      id: userIds?.nbm_branchids[0],
    };

    if (nbmChecker?.length > 0) {
      let branchData = {
        branch: nbmChecker[0]?.nbm_branch,
        id: nbmChecker[0]?.nbm_branchid,
      };

      localStorage.setItem("zzzz", JSON.stringify(branchData));
    } else {
      let branchData = {
        branch: userIds?.nbm_branches[0],
        id: userIds?.nbm_branchids[0],
      };
      localStorage.setItem("zzzz", JSON.stringify(branchData));
    }

    localStorage.setItem("userBranch", JSON.stringify(branchData));
    sessionStorage.setItem("userBranch", JSON.stringify(branchData));

    sessionStorage.setItem("SbmOrNbm", "NBM");
    localStorage.setItem("bmOrNbm", "NBM");
    window.location.reload();
    window.location.replace("/home");
  };

  const handleLogout = () => {
    let data = {
      userid: ids.userid,
    };
    dispatch(logOut(data, token));
    localStorage.clear();
    sessionStorage.clear();
    navigate("/signin");
    localStorage.setItem("logoutEvent", Date.now().toString());
    window.location.reload();
  };

  React.useEffect(() => {
    let data = {
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
      userid: ids.userid,
      hospid: ids.hospid,
    };

    dispatch(retrieveAllAssets(data, token));
  }, [dispatch]);
  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hosp_id: userIds.hospid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getUserData(data, token));
  }, [dispatch]);

  const handleBranch = (branch, id) => {
    setSelectedBranch({ branch: branch, id: id });

    let branchData = {
      branch: branch,
      id: id,
    };

    localStorage.setItem("userBranch", JSON.stringify(branchData));
    sessionStorage.setItem("userBranch", JSON.stringify(branchData));
    window.location.reload();
    window.location.replace("/home");
  };

  useEffect(() => {
    let check = userIds?.nbm_branchids.filter((item) => {
      return item === selectedBranch.id;
    });

    let filteredBranches = check.map((branchId) => {
      let index = userIds.nbm_branchids.indexOf(branchId);
      return {
        nbm_branch: userIds.nbm_branches[index],
        nbm_branchid: userIds.nbm_branchids[index],
      };
    });

    setNbmChecker(filteredBranches);
  }, [selectedBranch]);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDropdown = (listName) => {
    setDropdownOpen((prev) => (prev === listName ? "" : listName));
  };
  useEffect(() => {
    const storedItem = localStorage.getItem("selectedItem");
    if (storedItem) {
      setSelectedItem(storedItem);
    }
  }, []);

  const handleListItemClick = (itemName) => {
    setSelectedItem(itemName);
    localStorage.setItem("selectedItem", itemName);
    dispatch(setNavigationSource("assets"));
  };

  const routeNameMap = {
    "/": "Dashboard.",
    "/home": "Dashboard.",
    "/profile": "Profile",
    "/edit-profile": "Edit Profile",
    "/report": "Reports",
    "/user": "Users",
    "/user/:id": "Edit User",
    "/incident-list": "Incidents ",
    "/incident-list/:id": "Incident Detail",
    "/addUser": "Add User",
    "/add-asset": "Add Asset",
    "/asset-detail/:id": "Asset Details",
    "/user-manual": "User Manual ",
    "/department": "Main",
    "/calibration": "Callibration",
    "/not-calibrated": "Not Calibrated",
    "/calibration-not-required": "Calibrate Not Required",
    "/add-dep": "Add Department",
    "/editDepart/:id": "Edit Department",
    "/all-assets": "Assets",
    "/service-request/:id": "Service Request",
    "/service-others/:id": "Service Other",
    "/closed-service": "Close Service",
    "/closed-incident": "Closed Incident",
    "/open-incident": "Open Incident",
    "/open-service": "Open Services",
    "/service-list": "Services",
    "/clear-service/:id": "Clear Service",
    "/edit-asset/:id": "Edit Asset",
    "/contact": "Contact",
    "/pm-checklist": "Pm Checklist",
    "/pm-checklist/:id": "Pm Check List",
    "/depreciation": "Depreciation ",
    "/clear-incident/:id": "Clear Incident",
    "/record-incident/:id": "Recorderd Incident",
    "/department-asset-details/:id": "Asset List",
    "/department-asset-delete-details/:id": "Department Asset Delete",
    "/warranty-status/:id": "Warranty Status",
    "/bulk-upload-asset": "Bulk Upload Asset",
    "/bulk-upload-department": "Bulk Upload Department",
    "/amc-cmc": "Amc Cmc",
    "/amc": "Amc",
    "/cmc": "Cmc",
    "/plans": "Plans",
    "/asset-history": "Asset History",
    "/asset-history-list": "Asset History ",
    "/task": "Task",
    "/vendor-relations/:id": "Vendor Relations",
    "/checkin-checkout/:id": "Check-in-out",
    "/checkin-checkout-details/:id": "Checkin Checkout Detail",
    "/calendar": "Calender",
    "/notification": "Notification Tab",
    "/manual-details/:id": "User Manual Details",
    "/scan": "Qr Code Scanner",
    "/department-list": "Department ",
    "/vendor": "Vendor ",
    "/service-company": "Service Company ",
    "/check-in": "Check In",
    "/request": "Requests",
    "/check-out": "Check Out",
    "/m-report": "Report",
    "/sell-online": "Sell Online",
    "/not-working-asset": "Not Working Asset",
    "/Discarded": "Discarded",
  };

  const bmOrNbm = localStorage.getItem("bmOrNbm");

  useEffect(() => {
    const pathname = location.pathname;

    const dynamicRouteMatch = Object.keys(routeNameMap).find(
      (route) =>
        route.includes("/:id") && pathname.startsWith(route.split("/:id")[0])
    );

    let routeName = routeNameMap[pathname];

    if (!routeName && dynamicRouteMatch) {
      routeName = routeNameMap[dynamicRouteMatch];
    }

    if (routeName) {
      setHeading(routeName);
    }
  }, [location.pathname]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const drawer = (
    <>
      <Stack>
        <Toolbar sx={{ height: "80px" }}>
          {/* Vajra Logo */}
          <Box
            onClick={() => navigate("/home")}
            as="img"
            margin={"auto"}
            src={vajra}
            alt="logo"
            sx={{ height: "44px", width: "80px", cursor: "pointer" }}
          />
        </Toolbar>
        <Divider />
      </Stack>
      <CustomScrollbar>
        {" "}
        <div
          style={{
            height: "400px",
            marginLeft: "20px",
            maxHeight: "400px",
            overflow: "auto",
          }}
        >
          <List sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <SidebarItem
              button
              component={Link}
              to="/home"
              $selected={selectedItem === "Dashboard"}
              onClick={() => handleListItemClick("Dashboard")}
            >
              <IconWrapper $selected={selectedItem === "Dashboard"}>
                <DashboardIcon />
              </IconWrapper>
              <ListItemText primary="Dashboard" />
            </SidebarItem>

            <ListItem disablePadding>
              <ListItemButton onClick={() => handleDropdown("asset")}>
                <IconWrapper $selected={selectedItem === "Asset"}>
                  <DataThresholdingIcon />
                </IconWrapper>
                <ListItemText primary="Asset" />
                {dropdownOpen === "asset" ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              sx={{ display: dropdownOpen === "asset" ? "block" : "none" }}
            >
              <List sx={{ pl: 4 }}>
                <SidebarItem
                  button
                  component={Link}
                  to="/all-assets"
                  $selected={selectedItem === "All Assets"}
                  onClick={() => handleListItemClick("All Assets")}
                >
                  <ListItemText primary="All Assets" />
                </SidebarItem>
                <SidebarItem
                  button
                  component={Link}
                  to="/department-list"
                  $selected={selectedItem === "Departments"}
                  onClick={() => handleListItemClick("Departments")}
                >
                  <ListItemText primary="Departments" />
                </SidebarItem>
                <SidebarItem
                  button
                  component={Link}
                  to="/asset-history-list"
                  $selected={selectedItem === "Movements"}
                  onClick={() => handleListItemClick("Movements")}
                >
                  <ListItemText primary="Movements" />
                </SidebarItem>
                <SidebarItem
                  button
                  component={Link}
                  to="/depreciation"
                  $selected={selectedItem === "Depreciation"}
                  onClick={() => handleListItemClick("Depreciation")}
                >
                  <ListItemText primary="Depreciation" />
                </SidebarItem>

                <SidebarItem
                  button
                  component={Link}
                  to="/check-in"
                  $selected={selectedItem === "Check In"}
                  onClick={() => handleListItemClick("Check In")}
                >
                  <ListItemText primary="Check In" />
                </SidebarItem>
                <SidebarItem
                  button
                  component={Link}
                  to="/check-out"
                  $selected={selectedItem === "Check Out"}
                  onClick={() => handleListItemClick("Check Out")}
                >
                  <ListItemText primary="Check Out" />
                </SidebarItem>
                {userIds?.subs_plan !== "Basic" && (
                  <>
                    <SidebarItem
                      button
                      component={Link}
                      to="/sell-online"
                      $selected={selectedItem === "Sell Online"}
                      onClick={() => handleListItemClick("Sell Online")}
                    >
                      <ListItemText primary="Sell Online" />
                    </SidebarItem>
                  </>
                )}
              </List>
            </ListItem>

            <SidebarItem
              button
              component={Link}
              to="/incident-list"
              $selected={selectedItem === "Incident"}
              onClick={() => handleListItemClick("Incident")}
            >
              <IconWrapper $selected={selectedItem === "Incident"}>
                <DesignServicesIcon />
              </IconWrapper>
              <ListItemText primary="Incident" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/service-list"
              $selected={selectedItem === "Service"}
              onClick={() => handleListItemClick("Service")}
            >
              <IconWrapper $selected={selectedItem === "Service"}>
                <SettingsIcon />
              </IconWrapper>
              <ListItemText primary="Service" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/request"
              $selected={selectedItem === "Request"}
              onClick={() => handleListItemClick("Request")}
            >
              <IconWrapper $selected={selectedItem === "Request"}>
                <SmsIcon />
              </IconWrapper>
              <ListItemText primary="Request" />
            </SidebarItem>

            <ListItem disablePadding>
              <ListItemButton onClick={() => handleDropdown("user")}>
                <IconWrapper $selected={selectedItem === "User"}>
                  <GroupAddIcon />
                </IconWrapper>
                <ListItemText primary="User" />
                {dropdownOpen === "user" ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItemButton>
            </ListItem>
            {/* User List and Add User */}
            <ListItem
              disablePadding
              sx={{ display: dropdownOpen === "user" ? "block" : "none" }}
            >
              <List sx={{ pl: 4 }}>
                <SidebarItem
                  button
                  component={Link}
                  to="/user"
                  $selected={selectedItem === "User List"}
                  onClick={() => handleListItemClick("User List")}
                >
                  <ListItemText primary="User List" />
                </SidebarItem>
                {userIds?.subs_plan !== "Basic" && (
                  <>
                    <SidebarItem
                      button
                      component={Link}
                      to="/user-manual"
                      $selected={selectedItem === "Usermanual"}
                      onClick={() => handleListItemClick("Usermanual")}
                    >
                      <ListItemText primary="Usermanual" />
                    </SidebarItem>
                  </>
                )}

                <SidebarItem
                  button
                  component={Link}
                  to="/calendar"
                  $selected={selectedItem === "Calendar"}
                  onClick={() => handleListItemClick("Calendar")}
                >
                  <ListItemText primary="Calendar" />
                </SidebarItem>
              </List>
            </ListItem>
            {/* Request */}
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleDropdown("reports")}>
                <IconWrapper $selected={selectedItem === "Reports"}>
                  <AssignmentIcon />
                </IconWrapper>
                <ListItemText primary="Reports" />
                {dropdownOpen === "Reports" ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{ display: dropdownOpen === "reports" ? "block" : "none" }}
            >
              <List sx={{ pl: 4 }}>
                <SidebarItem
                  button
                  component={Link}
                  to="/report"
                  $selected={selectedItem === "All Reports"}
                  onClick={() => handleListItemClick("All Reports")}
                >
                  <ListItemText primary="All Reports" />
                </SidebarItem>

                <SidebarItem
                  button
                  component={Link}
                  to="/pm-checklist"
                  $selected={selectedItem === "PM Checklist"}
                  onClick={() => handleListItemClick("PM Checklist")}
                >
                  <ListItemText primary="PM Checklist" />
                </SidebarItem>
              </List>
            </ListItem>
          </List>
        </div>
      </CustomScrollbar>

      <div>
        <Stack gap={"20px"} justifyContent={"center"} alignItems={"center"}>
          <Button
            onClick={() => navigate("/contact")}
            variant="outlined"
            startIcon={<HeadsetMicIcon />}
            style={{
              border: "1px solid white",
              color: "white",
              width: "183px",
              borderRadius: "22px",
            }}
          >
            Contact
          </Button>
          <Button
            onClick={handleOpenModal}
            startIcon={<LogoutIcon />}
            variant="outlined"
            style={{
              border: "1px solid white",
              color: "white",
              width: "183px",
              borderRadius: "22px",
            }}
          >
            Log Out
          </Button>
        </Stack>
      </div>
    </>
  );

  return (
    <>
      {!isMobile ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
              bgcolor: "#F4F6F6",
              boxShadow: "none",
            }}
          >
            <Toolbar>
              {path !== "/home" && (
                <PreviousContainer>
                  <Tooltip title="Go back">
                    <IconButton
                      sx={{ bgcolor: "white" }}
                      onClick={() => navigate(-1)}
                    >
                      <KeyboardBackspaceIcon sx={{ color: "#4690FF" }} />
                    </IconButton>
                  </Tooltip>
                </PreviousContainer>
              )}
              <Stack
                sx={{
                  width: "400px",
                  alignItems: "start",
                  marginLeft: {
                    xs: "0px",
                    md: "15px",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#1B2535",
                    fontWeight: 700,
                    fontSize: {
                      xs: "14px",
                      md: "20px",
                    },
                    display: {
                      xs: "grid",
                      md: "flex",
                    },
                    alignItems: "baseline",
                    gap: "5px",
                  }}
                >
                  {heading}
                  <Typography color={"primary"} sx={{ fontWeight: 600 }}>
                    {bmOrNbm === "BM" ? "Bio Medical" : ""}
                  </Typography>
                </Typography>
              </Stack>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "35px",
                }}
              >
                <Tooltip title="Go to Non-Bio Medical Section">
                  <Fab
                    onClick={handleChange}
                    disabled={
                      nbmChecker.length === 0 && userIds.role === "Sub_User"
                    }
                    variant="extended"
                    size="small"
                    sx={{
                      boxShadow: "0px 0px 15px 0px #DBE7E5",
                      borderRadius: "36px",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      bgcolor: "white",
                      cursor: "pointer",
                      paddingLeft: "4px",
                      paddingRight: "12px",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      width: "auto",
                      gap: "5px",
                      height: "44px",
                      fontSize: "15px",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 38,
                        height: 38,
                        bgcolor: "#1B2535",
                        fontSize: "15px",
                      }}
                    >
                      <Typography variant="caption" sx={{ fontSize: "13px" }}>
                        NB
                      </Typography>
                    </Avatar>
                    Non Bio Medical
                  </Fab>
                </Tooltip>

                <BranchSelect
                  value={selectedBranch?.branch}
                  displayEmpty
                  sx={{
                    borderRadius: "36px",
                    height: "44px",
                    boxShadow: "0px 0px 15px 0px #DBE7E5",
                    "& fieldset": {
                      border: "none",
                      borderWidth: 0,
                    },
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {branchesAndIds?.map((item) => (
                    <MenuItem
                      value={item?.branch}
                      key={item?.id}
                      onClick={() => handleBranch(item?.branch, item?.id)}
                    >
                      {item?.branch}
                    </MenuItem>
                  ))}
                </BranchSelect>

                <Tooltip title="Scan qr-code to get asset detail.">
                  <IconButton
                    sx={{
                      bgcolor: "white",
                      boxShadow: "0px 0px 15px 0px #DBE7E5",
                    }}
                    onClick={() => navigate("/scan")}
                  >
                    <QrCodeScannerIcon sx={{ color: "#1B2535" }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Profile">
                  <IconButton
                    color="black"
                    onClick={() => {
                      handleListItemClick("Profile");
                      navigate("/profile");
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "#1B2535",
                        color: "white",
                        boxShadow: "0px 0px 15px 0px #DBE7E5",
                        width: "35px ",
                        height: "35px",
                        fontSize: "15px",
                      }}
                    >
                      {truncatedUsername}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>

              <Badge
                color="error"
                sx={{
                  "& .MuiBadge-badge": {
                    width: "15px",
                    height: "15px",
                    minWidth: "15px",
                    transform: "scale(1) translate(13%, -50%)",
                  },
                }}
              ></Badge>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              container={container}
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  bgcolor: "#1B2535",
                  color: "white",

                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            {<PrivateRoute />}
          </Box>
          <div style={{}}>
            <Modal
              keepMounted
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <ModalBox>
                <Stack
                  sx={{
                    alignItems: "flex-end",
                    justifyContent: "end",
                    paddingRight: "10px",
                  }}
                >
                  <IconButton onClick={handleCloseModal}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    gap: "20px",
                    flexDirection: "column",

                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton>
                    <LogoutIcon size={60} />
                  </IconButton>

                  <Typography variant="h6" style={{ fontWeight: 700 }}>
                    Log out!
                  </Typography>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    Are you sure you want to <br /> Log out this Session?
                  </Typography>
                  <Stack sx={{ width: "90%" }}>
                    <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
                  </Stack>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleLogout}
                    sx={{
                      borderRadius: "35px",
                      width: "100px",
                      textTransform: "none",
                      bgcolor: "rgba(70, 144, 255, 1)",
                    }}
                  >
                    Log Out
                  </Button>
                </Stack>
              </ModalBox>
            </Modal>
          </div>
        </Box>
      ) : (
        <Stack
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            position: "static",
          }}
        >
          <PrivateNavbar handleToggle={handleToggle} toggle={toggle} />
          <Stack
            sx={{
              paddingTop: "10px",
              paddingBottom: "30px",

              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <PrivateRoute />
          </Stack>
          <PrivateFooter />
        </Stack>
      )}
    </>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
