

import React, { useRef } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from "recharts";
import { jsPDF } from "jspdf";
import { Canvg } from "canvg";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";


const data = [
  { name: "January", open: 40, closed: 24 },
  { name: "February", open: 30, closed: 13 },
  { name: "March", open: 20, closed: 98 },
  { name: "April", open: 28, closed: 39 },
  { name: "May", open: 18, closed: 48 },
];

const TestingComponent = () => {


  const svgRef = useRef(null);

  const downloadPDF = async () => {
    const doc = new jsPDF("landscape", "px", "a4");
    const isMobile = window.innerWidth <= 768; // Adjust this value based on your mobile breakpoint

    if (isMobile) {
      const svgElement = svgRef.current.querySelector("svg");
      const svgData = new XMLSerializer().serializeToString(svgElement);

      // Increase the canvas size to improve image clarity
      const canvas = document.createElement("canvas");
      const scale = 3; // Increase scale for better quality
      const ctx = canvas.getContext("2d");
      canvas.width = svgElement.clientWidth * scale;
      canvas.height = svgElement.clientHeight * scale;
      ctx.scale(scale, scale);

      try {
        const canvg = await Canvg.fromString(ctx, svgData);
        canvg.render();
        const imgData = canvas.toDataURL("image/png");

        doc.addImage(imgData, "PNG", 15, 40, canvas.width / 3, canvas.height / 3); // Adjust size as needed
        doc.save("chart.pdf");
      } catch (error) {
        console.error("Error rendering SVG to PNG:", error);
      }
    } else {
      // Capture the chart as an image for desktop
      const chartElement = document.getElementById("chartSection");

      const chartWidth = chartElement.clientWidth;
      const chartHeight = chartElement.clientHeight;

      const maxChartWidth = 565;
      const maxChartHeight = 400;

      const aspectRatio = chartWidth / chartHeight;
      let chartDisplayWidth = maxChartWidth;
      let chartDisplayHeight = chartDisplayWidth / aspectRatio;

      if (chartDisplayHeight > maxChartHeight) {
        chartDisplayHeight = maxChartHeight;
        chartDisplayWidth = chartDisplayHeight * aspectRatio;
      }

      const chartCanvas = await html2canvas(chartElement, { scale: 3 }); // Increase scale for better quality
      const chartImageData = chartCanvas.toDataURL("image/png");

      doc.addImage(chartImageData, "PNG", 15, 40, chartDisplayWidth, chartDisplayHeight);
      doc.save("chart.pdf");
    }
  };
  return (
    <div>
      <h2>Asset Tracking Chart</h2>

{/* This is where the chart will render */}
<div id="chartSection" ref={svgRef}>
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="open" fill="#4979d1" />
      <Bar dataKey="closed" fill="#ee7743" />
    </BarChart>
  </ResponsiveContainer>
</div>

<button onClick={downloadPDF}>Download Chart as PDF</button>
      
    </div>
  )
}

export default TestingComponent

