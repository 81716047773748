import React from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Autocomplete,
  styled,
  Divider,
  Badge,
  FormHelperText,
  Modal,
  IconButton
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect } from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import {
  assetDetailsData,
  departmentAssetDetails,
  getDepartmentData,
  sellOnlineAsset,
} from "../redux/data/action";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
const Field = styled(TextField)(({ theme }) => ({
  background: "rgba(244, 246, 246, 1)",
  "& fieldset": { border: "none" },
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));


const SellOnlinePage = () => {
  const departmentData = useSelector((store) => store.data.department) || [];
  const [showDetail, setShowDetail] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const navigate = useNavigate();


  const departAssetData = useSelector(
    (store) => store.data.departmentAssetDetails
  );

  const detailData = useSelector((store) => store.data.assetDetails);
  const sellOnlineData = useSelector((store) => store.data);
  // console.log(sellOnlineData)



  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const [usefulLife, setUsefulLife] = useState("");
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const [fileUrl, setFileUrl] = useState([]);
  const [department, setDepartment] = useState("");
  const [asset, setAsset] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [price, setPrice] = useState("");
  const [isPriceError, setIsPriceError] = useState("");
  const [isUsefulLifeError, setIsUsefulLifeError] = useState("");
  const [isPhotoError, setIsPhotoError] = useState(null);
  const [yearlyData, setYearlyData] = useState([]);
  const [assetId, setAssetId] = useState("");
  const [assetValue, setAssetValue] = useState("");
  const [data, setData] = useState({});
  const [model, setModel] = useState('')
  const [brand, setBrand] = useState('')

  const [toggle, setToggle] = useState(false);
  const uploadedImageName = fileUrl?.map((el) => el?.imageid);
  let idSplit = assetId.substring(0, 4);
  const filteredDepartments = departmentData.filter(
    (department) => parseInt(department.totalassets) > 0
  );
  // console.log("filteredDepartments", filteredDepartments);
  const compressImage = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 600;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File(
              [blob],
              `${idSplit}_` + uuid() + ".jpg",
              {
                type: "image/jpeg",
              }
            );

            const reader = new FileReader();
            reader.onloadend = () => {
              let sendData = {
                hospid: userIds?.hospid,
                userid: userIds?.userid,
                imageid: `${idSplit}_` + uuid() + ".jpg",
                image: reader.result,
                branchid: userBranch?.id,
              };
              setFileUrl([...fileUrl, sendData]);
            };
            reader.readAsDataURL(compressedFile);
          },
          "image/jpeg",
          0.7
        );
      };
    };

    reader.readAsDataURL(file);
  };
  const handlePhotoUpload = (e) => {
    const singlefile = e.target.files[0];

    const newFiles = Array.from(e.target.files).filter((file) => {
      const fileType = file.type.split("/")[1];
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 4 * 1024 * 1024; // 4 MB

      if (allowedTypes.indexOf(file.type) === -1) {
        setIsPhotoError(`Can't upload ${fileType} file.`);
        return false;
      }

      if (file.size > allowedSize) {
        // compressImage(file);
        setIsPhotoError(`Can't upload image more than 4MB.`)
      } else {
        setIsPhotoError(null);
        const reader = new FileReader();

        reader.onloadend = () => {
          let sendData = {
            hospid: userIds?.hospid,
            userid: userIds?.userid,
            imageid: `${idSplit}_` + uuid() + `.${fileType}`,
            image: reader.result,
            branchid: userBranch?.id,
          };
          setFileUrl([...fileUrl, sendData]);
        };
        reader.readAsDataURL(singlefile);
      }
    });
  };

  const removePhoto = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      imageid: id,
      branchid: userBranch?.id,
    };

    setFileUrl(fileUrl.filter((item) => item.imageid !== id));
  };
  const handleUsefulLife = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]\d*$/;
    setUsefulLife(value);
    if (value === "" || regex.test(value)) {
      setUsefulLife(value);
      setIsUsefulLifeError("");
      setShow(false);
      setYearlyData([]);
    }
    if (value !== "" && !regex.test(value)) {
      setIsUsefulLifeError("Enter valid number");
    }
    if (value === "") {
      setIsUsefulLifeError("Please fill the field");
    }
  };

  const handlePrice = (e) => {
    const value = e.target.value;
    const regex = /^\d*$/; 

    if (value === "") {
        setIsPriceError("Please fill the field");
        setShow(false);
        setYearlyData([]);
        setPrice(""); 
    } else if (regex.test(value)) {
        setPrice(value);
        setIsPriceError("");
        setShow(false);
        setYearlyData([]);
    } else {
        setIsPriceError("Enter valid price");
    }
};

  useEffect(() => {
    let data = {
      hospid: userIds?.hospid,
      userid: userIds?.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };
    dispatch(getDepartmentData(data, token));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(detailData)?.length > 0) {
      setData(detailData);
    }
  }, [detailData]);


  const handleSubmit = () => {
    price === "" && setIsPriceError("Enter valid price");
    usefulLife === "NA" && setIsUsefulLifeError("Enter valid year");
    price === "" && setIsPriceError("Please fill the field");
    usefulLife === "" && setIsUsefulLifeError("Please fill the field");
    if (!fileUrl || fileUrl.length === 0) {
      setIsPhotoError("Asset image is a mandatory field");
  }
    if (
      price !== "NA" &&
      usefulLife !== "NA" &&
      price !== "" &&
      usefulLife !== "" &&
      isPriceError === "" &&
      isUsefulLifeError === "" &&
      isPhotoError === ""
    ) {
      let usefulLifeCount = usefulLife;
      const depreciationPercentage = (2 / usefulLifeCount) * 100;
      let remainingPrice = price;

      const data = [];
      for (let year = 1; year <= usefulLifeCount; year++) {
        const depreciationExpense = Math.floor(
          (depreciationPercentage / 100) * remainingPrice
        );
        data.push({ year, depreciationExpense });
        remainingPrice -= depreciationExpense;
      }

      setYearlyData(data);
      setShow(true);
    }
    let sellData = {
      "hospid": userIds?.hospid,
      "hospname": userIds?.hospname,
      "branchid": userBranch?.id,
      "branchname": userBranch?.branch,
      "assetid": assetId,
      "assetname": assetValue,
      "modelno": model,
      "department": department,
      "brand": brand,
      "price": price,
      "prodimgs": fileUrl.map(file => file.imageid),
      "userid": userIds?.userid,
      "username": userIds?.username
    }
    dispatch(sellOnlineAsset(sellData))
    setBrand('')
    setAssetValue('')
    setModel('')
    setDepartment('')
    setPrice('')
    setFileUrl([])
    setOpenModal(true)
  };

  const handleDepartment = (e, item) => {
    if (item !== null && item !== undefined) {
      setSelectedId(item.id);
      setDepartment(item.name);
      setShow(false);
      setShowDetail(false);
      setYearlyData([]);
      setAssetValue("");
      let data = {
        hospid: userIds?.hospid,
        userid: userIds?.userid,
        branchid: userBranch?.id,
        depart_ids: userAccess?.departmentids,
      };
      dispatch(getDepartmentData(data, token));
      setData({});
    }
  };
  // console.log("data", data);

  const handleAssetName = (e, item) => {
    if (item !== null && item !== undefined) {
      setAssetId(item.value);
      setToggle(true);
      setAsset(item.name);
      setAssetValue(item.label);
      setShowDetail(true);
    }
    if (item === null && item === undefined) {
      setShow(false);
    }
  };

  const handleModel = (e) => {

    setModel(e.target.value)
  };

  const handleBrand = () => {
    setBrand(data?.modelno
    )
  }
  // console.log("assetId", assetId);
  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      deptid: selectedId,
      branchid: userBranch?.id,
    };

    dispatch(departmentAssetDetails(data, token));
  }, [dispatch, selectedId]);

  useEffect(() => {
    setUsefulLife(detailData?.lifeyrs);
    setPrice(detailData?.price);
    setBrand(detailData?.brand
    )
    setModel(detailData?.modelno)
  }, [detailData]);

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      assetid: assetId,
      branchid: userBranch?.id,
    };
    dispatch(assetDetailsData(data, token));
    setToggle(false);
  }, [dispatch, assetId, toggle]);

  let print = {
    name: detailData.name,
    dop: detailData?.dop,
    price: price,
    usefulLife: usefulLife,
    department: detailData.dept,
    yearlyData: yearlyData,
  };

  const downloadPDF = (asset) => {
    const pdf = new jsPDF();

    pdf.setFillColor(255, 251, 244);
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.width,
      pdf.internal.pageSize.height,
      "F"
    );

    // Title
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(16);
    pdf.text(
      "Calculated  Depreciation  Expense",
      pdf.internal.pageSize.width / 2,
      18,
      "center"
    );
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(14, 79, 205); // #0E4FCD color
    pdf.line(
      pdf.internal.pageSize.width / 2 - 90,
      25,
      pdf.internal.pageSize.width / 2 + 90,
      25
    );

    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(12);
    pdf.text(`Asset Name:`, 20, 50);
    pdf.setTextColor(0, 0, 0); // Black color for values
    pdf.text(`${asset.name}`, 70, 50);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Date of Purchase:`, 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.dop}`, 70, 60);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Price:`, 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.price}`, 70, 70);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Useful Life:`, 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.usefulLife} years`, 70, 80);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Department:`, 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.department}`, 70, 90);

    // Additional Title and Underline above the table
    pdf.setTextColor(14, 79, 205); // #0E4FCD color
    pdf.setFontSize(16);
    pdf.text("Depreciation", pdf.internal.pageSize.width / 2, 105, "center");
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(0, 0, 0);
    pdf.line(
      pdf.internal.pageSize.width / 2 - 30,
      110,
      pdf.internal.pageSize.width / 2 + 30,
      110
    );

    const data = asset.yearlyData.map((item) => [
      `Depreciation Expense for year : ${item.year}`,
      `Expense : ${item.depreciationExpense}`,
    ]);

    pdf.autoTable({
      body: data,
      startY: 120,
    });

    pdf.save("Depreciation.pdf");
  };
  const handleClear = () => {
    setSelectedId("");
    setDepartment("");
    setAssetId("");
    setAsset("");
    setAssetValue("");
    setPrice("");
    setUsefulLife("");
    setYearlyData([]);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const ModalBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    background: "white",
    border: "0px solid #000",
    boxShadow: 24,
    p: 4,
    height: "200px",
    borderRadius: "6px",
  }));

  const handleFormSubmit = () =>{
    setBrand('')
    setPrice('')
    setModel('')
    navigate('/home')
  }

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const validateFields = () => {
    if (
        price === "" ||
        usefulLife === "" ||
        usefulLife === "NA" ||
        (fileUrl && fileUrl.length === 0)
    ) {
        setIsSubmitDisabled(true);
    } else {
        setIsSubmitDisabled(false);
    }
};

useEffect(() => {
    validateFields();
}, [price, usefulLife, fileUrl]);

const isGuest = userAccess && userAccess.role === 'Guest';
  return (
    <>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",

          height: "100%",
        }}>
        <Stack
          sx={{
            width: { xs: 'auto', sm: 'auto', md: '50%', lg: '40%', xl: '40%', },

            borderRadius: "8px",

            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            gap: "10px",
          }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",

              bgcolor: "white",

              padding: "20px",
            }}>
            <Label variant="h6" color="primary">
              Advert equipments online
            </Label>
          </Stack>

          <Box sx={{ bgcolor: "white", padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",

                bgcolor: "white",
              }}>
              <Label variant="h6" style={{ color: "#4690FF" }}>
                New Advert
              </Label>
            </Box>
            <Divider />

            <Stack
              flexDirection={"row"}
              gap="10px"
              alignItems={"center"}
              sx={{
                width: "100%",
                overflowY: "auto",
                
                padding: "5px 0",

                height: "40px",
                marginTop: "10px",
              }}>
     
            </Stack>
            <Stack sx={{   flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              }, width: "100%", gap: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  width: "100%",
                  gap: "20px",
                  textAlign: "start",
                }}>
                <Label>Asset Details</Label>

                <Autocomplete
                  value={department}
                  onChange={handleDepartment}
                  options={filteredDepartments?.map((option) => ({
                    label: `${option?.departname}`,
                    name: option?.departname,
                    id: option?.departid,
                  }))}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      label="Choose Department"
                      variant="filled"
                      sx={{ width: "100%" }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        sx: {
                          "& input:focus": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                    />
                  )}
                />

                <Autocomplete
                  onChange={handleAssetName}
                  value={assetValue}
                  options={departAssetData?.map((option) => ({
                    label: `${option?.name} (${option?.assetcode})`,
                    value: option?.assetid,
                    name: option?.name,
                  }))}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      label="Choose Asset"
                      variant="filled"
                      sx={{ width: "100%" }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        sx: {
                          "& input:focus": {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                    />
                  )}
                />

                <Stack
                  sx={{
                    flexGrow: 1,
                    flexDirection: "row",
                    width: "100%",
                    gap: "10px",
                  }}>
                  <Field
                    label="Model No."
                    value={model || ''}
                    onChange={handleModel}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    sx={{ width: "50%" }}
                    disabled
                  />
                  <Field
                    label="Brand"
                    value={brand || ''}
                    onChange={handleBrand}
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    sx={{ width: "50%" }}
                    disabled
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  width: "100%",
                  gap: "20px",
                  textAlign: "start",
                }}>
                <Label>Quotation</Label>
                <Field
                  label="Expected Price Range"
                  multiline
                  value={price || ''}
                  onChange={handlePrice}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  type="number"
                  disabled
                  
                />

                <Stack>
                  <Stack
                    flexDirection={"row"}
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Label>Asset Images</Label>

                    {uploadedImageName?.length < 3 && (
                      <>
                        <label for="img">
                          <FileUploadIcon
                            size={20}
                            style={{ color: "rgba(27, 37, 53, 1)" }}
                          />
                        </label>
                        <input
                          type="file"
                          name="uploadfile"
                          id="img"
                          style={{
                            display: "none",
                            width: "42px",
                            height: "42px",
                          }}
                          onChange={handlePhotoUpload}
                        />
                      </>
                    )}
                  </Stack>

                  <Stack
                    sx={{
                      minHeight: "50px",
                      maxHeight: "150px",
                      width: "auto",
                      flexDirection: "row",
                      // overflowX: "auto",
                      // overflowY: "hidden",
                      gap: "20px",
                      alignItems: "center",
                    }}>
                    {fileUrl?.map((item, index) => (
                      <Badge
                        key={index}
                        badgeContent={
                          <CloseIcon
                            size={15}
                            style={{
                              color: " rgba(255, 58, 68, 1)",
                            }}
                            onClick={() => removePhoto(item?.imageid)}
                          />
                        }
                        sx={{
                          // height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "10px",
                          border: "0.5px solid rgba(27, 37, 53, 1)",
                          padding: "5px",
                        }}>
                        <Box
                          as="img"
                          src={item?.image}
                          alt=""
                          sx={{ width: "70px", height: "90px" }}
                        />
                      </Badge>
                    ))}
                  </Stack>
                  {isPhotoError && (
                    <HelperText sx={{ color: "red" }}>{isPhotoError}</HelperText>
                  )}
                </Stack>
              </Box>
            </Stack>

            <Stack
              sx={{
                width: "100%",
                alignItems: "flex-end",
                gap: "20px",
                marginTop: "20px",
              }}>
              <Divider sx={{ width: "100%" }} />
              <Button
                onClick={isGuest ? null : handleSubmit}
                variant="contained"
                disabled={isSubmitDisabled}
                sx={{
                  width: "154px",
                  borderRadius: "22px",
                  bgcolor: "rgba(70, 144, 255, 1)",
                }}>
                Submit
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      {/* Modal Section */}

      <Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <ModalBox>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}
          >
            <IconButton onClick={handleCloseModal}>
              <CloseIcon sx={{ fill: "#000" }} />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
              position: "absolute",
              top: "50px",
              padding: "10px",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
              Form submitted succesfully. The vajra team will get back to you soon.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "150px", borderRadius: "22px", fontWeight: 600 }}
              onClick={handleFormSubmit}
            >
              Okay
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
    </>
  );
};

export default SellOnlinePage;
