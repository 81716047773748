import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Close } from "@mui/icons-material";
import csvFile from "../csv/asset.csv";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  csvFileUpload,
  csvFileUploadSubmit,
  deleteCsvFileUpload,
} from "../redux/nbmData/action";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import SuccessPopUp from "../components/SuccessPopUp";
import { Link } from "@mui/material";
import csvTemplate from '../../src/csv/asset.csv'


const BulkUploadAssetPopUp = () => {
  const [open, setOpen] = useState(false);
  const response = useSelector((store) => store.data.csvFileUploadSubmit);
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const [files, setFiles] = useState([]);
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [error, setError] = useState("");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSubmit, setShowSubmit] = useState(false);

  const text = "CSV file uploaded";

  const [toggle, setToggle] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files).slice(0, 3); // Allow up to 3 files
    setFiles(selectedFiles);

    const parsedData = [];
    let hasError = false;

    for (const file of selectedFiles) {
      const fileType = getFileType(file);
      if (fileType !== "csv") {
        setError(`Please upload csv file`);
        hasError = true;
        continue; // Skip processing this file and move to the next
      }

      try {
        const dataUrl = await readFileAsDataUrl(file);
        parsedData.push({ filename: file.name, dataUrl, type: "csv" });
        setData({ filename: file.name, dataUrl, type: "csv" });
      } catch (error) {
        setError(`Error parsing ${file.name}: ${error.message}`);
        hasError = true;
      }
    }

    if (!hasError) {
      setError(""); // Clear any previous errors
    }

    parsedData.forEach(async (fileData) => {
      let datas = {
        hospid: ids.hospid,
        userid: ids.userid,
        csvfileid: `${"csvid_"}` + uuid() + `.${fileData.type}`,
        csvfile: fileData.dataUrl,
        branchid: userBranch?.id,
      };
      // setToggle(true);
      setShowSubmit(true);

      setCsvData(datas);
      dispatch(csvFileUpload(datas));
    });
  };

  const getFileType = (file) => {
    return file.name.split(".").pop().toLowerCase();
  };

  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const dataUrl = event.target.result;
        resolve(dataUrl);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleDelete = (id) => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      fileid: id,
      branchid: userBranch?.id,
    };
    setToggle(false);
    setShowSubmit(false);
    dispatch(deleteCsvFileUpload(data));

    setFiles(files?.filter((el) => el.name !== id));
  };

  const handleSave = () => {
    if (files.length === 0) {
      setError("Please upload at least one CSV file.");
    } else {
      setError("");
      let datas = {
        hospid: ids.hospid,
        userid: ids.userid,
        hospname: ids.hospname,
        city: ids.city,
        username: ids.username,
        plan: ids.subs_plan,
        email: ids.email,
        mobile: ids.mobile,
        file_id: csvData.csvfileid,
        filetype: "Asset",
        branchid: userBranch?.id,
      };
      setToggle(true);

      dispatch(csvFileUploadSubmit(datas))
        .then(() => {
          setOpenModal(true);
        })
        .catch((error) => {
          console.error(error);
        });
      handleClose();
    }
  };

  const handleDownload = async () => {
    const response = await fetch(csvFile);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "asset.csv";
    anchor.click();

    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (response === true && toggle === true && files.length !== 0) {
      setOpenModal(true);
    }
  }, [response]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userAccess =
  JSON.parse(sessionStorage.getItem("user_access")) ||
  JSON.parse(localStorage.getItem("user_access"));

  const isGuest = userAccess && userAccess.role === 'Guest';

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        disabled={isGuest}
        variant="contained"
        color="info"
        sx={{ width: "200px", borderRadius: "22px" }}>
        Bulk Upload
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Stack
            sx={{
              width: "350px",
              flexDirection: "column",
              alignItems: "flex-end",
            }}>
            {" "}
            <IconButton>
              <Close
                onClick={() => {
                  handleClose();
                  setShowSubmit(false);
                }}
              />
            </IconButton>
          </Stack>

          <Stack display="flex" alignItems="center" justifyContent="center">
            <FileUploadIcon size={60} color="primary" />
            <Typography variant="h6">Import assets easily!</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack textAlign="center">
            <Typography variant="body1" paragraph>
              Bulk upload assets through CSV file.
              <br />
              Make it easier with this{" "}
              {/* <span style={{ color: "rgba(70, 144, 255, 1)" }}>
                CSV template.
              </span> */}
               <Link href={csvTemplate} download="asset.csv" underline="none">
                CSV template
              </Link>
            </Typography>

            <Divider style={{ margin: "16px 0" }} />

            <Stack
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                gap: "20px",
              }}>
              

              <div>
                {!showSubmit ? (
                  <>
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                      id="fileInput"
                      multiple
                    />
                    <label htmlFor="fileInput">
                      <Button
                        variant="contained"
                        component="span"
                        sx={{
                          width: "150px",
                          borderRadius: "22px",
                        }}>
                        Upload CSV
                      </Button>
                    </label>
                  </>
                ) : (
                  <>
                    {files.length > 0 && (
                      <Typography
                        variant="body1"
                        style={{
                          margin: "10px 0",
                          fontWeight: "bold",
                        }}></Typography>
                    )}
                    <Stack
                      sx={{
                        bgcolor: "white",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      {files?.map((file) => (
                        <>
                          <Stack sx={{ height: "40px", padding: "5px" }}>
                            <Stack
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                width: "150px",
                                backgroundColor: "rgba(244, 246, 246, 1)",
                                padding: "6px 16px",
                                borderRadius: "22px",
                              }}>
                              file.csv
                              <Close onClick={() => handleDelete(file.name)} />
                            </Stack>
                          </Stack>
                        </>
                      ))}

                      <Button
                        variant="contained"
                        component="span"
                        sx={{
                          width: "150px",
                          borderRadius: "22px",
                        }}
                        onClick={handleSave}>
                        Submit
                      </Button>
                    </Stack>
                  </>
                )}
              </div>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>

      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"CSV uploaded successfully ."}
        subText2={" "}
      />
    </div>
  );
};

export default BulkUploadAssetPopUp;
