import { useEffect, useState } from "react";
import "./App.css";
import MainRoutes from "./routes/MainRoutes";
import NoInternet from "./components/NoInternet";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

// right click disable code

  // document.addEventListener('contextmenu', (e) => {
  //   e.preventDefault();
  // });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "p" ) {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logoutEvent") {
        window.location.reload();

        localStorage.clear();
        sessionStorage.clear();
      }
      if (event.key === "loginEvent") {
        const delayedFunction = () => {
          window.location.reload();
        };

        const timeoutId = setTimeout(delayedFunction, 3000);

        return () => clearTimeout(timeoutId);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  

  return (
    <div className="App">
      {isOnline ? (
        <>
          <MainRoutes />
        </>
      ) : (
        <NoInternet />
      )}
    </div>
  );
}

export default App;
