import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
    Menu,
    Popover,
    Modal,
    IconButton,
    Divider,
  } from "@mui/material";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
    AmcCmcReq,
    AmcCmcTaskCompleted,
    calender,
    CalibrationReq,
    CalibrationTaskCompleted,
    departmentUserDetails,
    WarrantyRequest,
    WarrantyTaskCompleted,
  } from "../redux/nbmData/action";
  import styled from "styled-components";
  import CalenderModal from "./CalenderModal";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { DatePicker } from "@mui/x-date-pickers";
  import dayjs from "dayjs";
  import CloseIcon from "@mui/icons-material/Close";
  import rightTickImage from "../../src/images/rightTickImage.png";
  
  
  const CalenderTaskComponent = ({
    eventData,
    taskTitle,
    selectedMonth,
    selectedYear,
    selectedEventData,
    onUpdateEventData,
    isDataOpen,
    setIsDataOPen,
  }) => {
    const getUser = useSelector((store) => store?.data?.departmentUserList);
    const getCalib = useSelector(
      (store) => store?.data?.calenderData?.old_calibs
    );
    // console.log(getCalib);
    const initialInputValues = {};
    const initialSelectedUsers = {};
    const validEventData = Array.isArray(eventData) ? eventData : [];
  
    const [enteredValue, setEnteredValue] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [taskData, setTaskData] = useState(eventData);
    const [inputValues, setInputValues] = useState(initialInputValues);
    const [durationError, setDurationError] = useState("");
    const [getDetail, setGetDetail] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState(initialSelectedUsers);
    const [selectedUserDetail, setSelectedUserDetail] = useState({});
    const [isAnyTaskStatusNotEmpty, setIsAnyTaskStatusNotEmpty] = useState(false);
    const [getId, setGetId] = useState("");
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState("");
    const [isSuccessfullModalOpen, setIsSuccessfullModalOpen] = useState(false);
    // const [isDataOpen,setIsDataOPen] = useState(false)
  
    const YesDateBox = styled(Box)(({ theme }) => ({
      display: "flex",
      gap: "5px",
      justifyContent: "center",
    }));
  
    const DateBox = styled(Box)(({ theme }) => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }));
  
    const CustomText = styled(Typography)(({ theme }) => ({
      fontSize: "12px",
      textAlign: "left",
      fontWeight: "400px",
    }));
  
    const DueDateBox = styled(Box)(({ theme }) => ({
      display: "flex",
      justifyContent: "center",
    }));
  
    const ModalBox = styled(Box)(({ theme }) => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 300,
      background: "white",
      border: "0px solid #000",
      boxShadow: 24,
      p: 4,
      width: "350px",
      height: "300px",
      borderRadius: "6px",
    }));
  
    // const [day, month, year] =
    //   getId && getId.calib_due_date.split("-").map(Number);
  
    // const calibrationExpDate = new Date(year, month - 1, day);
  
    // calibrationExpDate.setMonth(calibrationExpDate.getMonth() + 1);
  
    // const newCalibrationExpDate = `${calibrationExpDate
    //   .getDate()
    //   .toString()
    //   .padStart(2, "0")}-${(calibrationExpDate.getMonth() + 1)
    //   .toString()
    //   .padStart(2, "0")}-${calibrationExpDate.getFullYear()}`;
  
    const calculateNewExpiryDate = (dueDate, monthsToAdd = 1) => {
      if (!dueDate) return "";
      const [day, month, year] = dueDate.split("-").map(Number);
      const expDate = new Date(year, month - 1, day);
      expDate.setMonth(expDate.getMonth() + monthsToAdd);
    
      return `${expDate.getDate().toString().padStart(2, "0")}-${(expDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${expDate.getFullYear()}`;
    };
    
    // Get due date based on task type
    const getExpiryDate = () => {
      if (getId) {
        if (taskTitle === "Calibration") {
          return calculateNewExpiryDate(getId.calib_due_date);
        } else if (taskTitle === "AMC/CMC") {
          return calculateNewExpiryDate(getId.cmc_amc_due_date);
        }else if (taskTitle === "Warranty") {
          return calculateNewExpiryDate(getId.warranty_exp_date);
        }
      }
      return "";
    };
    
    // Get new expiration date
    const newExpDate = getExpiryDate();
  
    // console.log(getUser);
    let userIds =
      JSON.parse(sessionStorage.getItem("userData")) ||
      JSON.parse(localStorage.getItem("userData"));
  
    let userBranch =
      JSON.parse(sessionStorage.getItem("userBranch")) ||
      JSON.parse(localStorage.getItem("userBranch"));
  
    let userAccess =
      JSON.parse(sessionStorage.getItem("user_access")) ||
      JSON.parse(localStorage.getItem("user_access"));
  
    const Field = styled(TextField)(({ theme }) => ({
      backgroundColor: "rgba(244, 246, 246, 1)",
      "& label.Mui-focused": {
        color: "rgba(166, 166, 166, 1)",
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "rgba(244, 246, 246, 1)",
      },
      "&:hover .MuiFilledInput-root": {
        backgroundColor: "rgba(244, 246, 246, 1)",
      },
      "& fieldset": {
        border: "none",
      },
    }));
  
    const dispatch = useDispatch();
    
    useEffect(() => {
      let data = {
        hosp_id: userIds?.hospid,
        adminid: userIds?.userid,
        branchid: userBranch?.id,
        departid: "all",
      };
      dispatch(departmentUserDetails(data));
    }, []);
  
    const handleClose2 = () => {
      setIsAnyTaskStatusNotEmpty(true);
      setSuccessDialogOpen(false);
    };
  
    const handleClose = () => {
      setIsAnyTaskStatusNotEmpty(false);
      handleClose2();
      setIsDataOPen(false);
    };
    const handleInputChange = (event, asset_id) => {
      const { value } = event.target;
      const numericValue = value.replace(/\D/, "").slice(0, 2);
    
      // Prevent the user from entering only "0"
      if (numericValue === "0") {
        setDurationError((prevErrors) => ({
          ...prevErrors,
          [asset_id]: "Year cannot be 0",
        }));
        return;
      }
    
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [asset_id]: numericValue,
      }));
    
      if (numericValue === "") {
        setDurationError((prevErrors) => ({
          ...prevErrors,
          [asset_id]: "Enter year",
        }));
      } else {
        setDurationError((prevErrors) => ({
          ...prevErrors,
          [asset_id]: "",
        }));
      }
    
      const updatedModalData = taskData.map((item) => {
        if (item.asset_id === asset_id) {
          return {
            ...item,
            req_status: numericValue !== "" ? "Requested" : "",
          };
        }
        return item;
      });
      setTaskData(updatedModalData);
    };
    const handleButtonClick = (data) => {
      setGetDetail(data);
    };
  
    const handleClicks = (event) => {
      if (event) {
        setAnchorEl(event.currentTarget);
      }
    };
  
    const handleSelectChange = (event, asset_id) => {
      const { value } = event.target;
  
      const selectedUserObject = getUser?.find(
        (user) => user.userid === value.userid
      );
  
      setSelectedUserDetail(selectedUserObject);
  
      setSelectedUsers((prevUsers) => ({
        ...prevUsers,
        [getDetail.asset_id]: selectedUserObject || value,
      }));
  
      const updatedModalData = taskData.map((item) => {
        if (item.asset_id === getDetail.asset_id) {
          return {
            ...item,
            req_status: selectedUserObject ? "Requested" : "",
          };
        }
        return item;
      });
      setTaskData(updatedModalData);
    };
  
    const handleCloses = () => {
      setAnchorEl(null);
    };
  
    const checkTaskStatus = () => {
      const anyNotEmpty = eventData.some(
        (item) =>
          item.task_status !== "" &&
          item.task_status !== "NA" &&
          item.task_status !== "Completed"
      );
      setIsAnyTaskStatusNotEmpty(anyNotEmpty);
    };
  
    React.useEffect(() => {
      checkTaskStatus();
    }, [eventData]);
  
    const handleRenewClick = (asset_id, operationType) => {
      const asset = taskData.find((item) => item.asset_id === asset_id);
      const enteredValue = inputValues[asset_id] || "";
      const selectedUser = selectedUsers[asset_id] || {};
      let previousUserDetail = {};
  
      if (asset.assign_userid) {
        previousUserDetail =
          getUser.find((user) => user.userid === asset.assign_userid) || {};
      }
  
      if (enteredValue === "") {
        setDurationError((prevErrors) => ({
          ...prevErrors,
          [asset_id]: "Enter year",
        }));
        return;
      }
  
      if (asset && enteredValue !== "") {
        const { userid, username, email, mobile } = selectedUser;
        const commonData = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          hospname: userIds.hospname,
          hospcity: userIds.city,
          assetid: asset.asset_id,
          assetname: asset.asset_name,
          brand: asset.manufacturer,
          depart: asset.department,
          dop: asset.date_of_purchase,
          req_years: enteredValue,
          branchid: userBranch?.id,
          branchname: userBranch.branch,
          depart_id: asset.department_id,
          req_id:
            selectedUsers[asset_id] === "assignedToYourself"
              ? userIds.userid
              : selectedUserDetail.userid ||
                previousUserDetail.userid ||
                userIds.userid,
          reqname:
            selectedUsers[asset_id] === "assignedToYourself"
              ? userIds.username
              : selectedUserDetail.username ||
                previousUserDetail.username ||
                userIds.username,
          reqemail:
            selectedUsers[asset_id] === "assignedToYourself"
              ? userIds.email
              : selectedUserDetail.email ||
                previousUserDetail.email ||
                userIds.email,
          reqphone:
            selectedUsers[asset_id] === "assignedToYourself"
              ? userIds.mobile
              : selectedUserDetail.mobile ||
                previousUserDetail.mobile ||
                userIds.mobile,
        };
  
        let operationSpecificData = {};
        let dispatchAction;
  
        if (operationType === "Calibration") {
          operationSpecificData = {
            expdate: asset.calib_due_date,
          };
          dispatchAction = CalibrationReq;
        } else if (operationType === "AMC_CMC") {
          operationSpecificData = {
            expdate: asset.cmc_amc_due_date,
            amc_cmc: asset.cmc_amc,
          };
          dispatchAction = AmcCmcReq;
        } else if (operationType === "Warranty") {
          operationSpecificData = {
            expdate: asset.cmc_amc_due_date,
            amc_cmc: asset.cmc_amc,
          };
          dispatchAction = WarrantyRequest;
        }
  
        const finalData = { ...commonData, ...operationSpecificData };
  
        if (typeof dispatchAction !== "function") {
          console.error("dispatchAction is not a function:", dispatchAction);
          return;
        }
  
        dispatch(dispatchAction(finalData))
          .then(() => {
            const updatedData = taskData.map((item) =>
              item.asset_id === asset_id
                ? { ...item, req_status: "Requested" }
                : item
            );
            setTaskData(updatedData);
            onUpdateEventData(updatedData);
  
            const requestData = {
              hospid: userIds.hospid,
              userid: userIds.userid,
              branchid: userBranch?.id,
              month:
                selectedMonth < 9 ? `0${selectedMonth + 1}` : selectedMonth + 1,
              year: selectedYear,
              depart_ids: userAccess.departmentids,
            };
            dispatch(calender(requestData));
  
            setSuccessDialogOpen(true);
            setSelectedUserDetail({});
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    };
  
    const handleClick = (event, data) => {
      setGetId(data);
    };
  
    eventData.forEach((item) => {
      initialInputValues[item.asset_id] =
        item.req_status === "Requested" ? item.duration : "";
      initialSelectedUsers[item.asset_id] =
        item.req_status === "Requested" ? item.assigned_to : "";
    });
  
    useEffect(() => {
      setInputValues(initialInputValues);
      setSelectedUsers(initialSelectedUsers);
    }, [eventData, validEventData]);
  
    const handleStartDateChange = (date) => {
      setStartDate(date);
  
      if (date && endDate) {
        setError("");
      }
    };
  
    const handleEndDateChange = (date) => {
      setEndDate(date);
  
      if (startDate && date) {
        setError("");
      }
    };

    const handleSubmitDate = (taskType) => {
      if (startDate && endDate) {
        const parsedStartDate = dayjs(startDate, "DD-MM-YYYY");
        const parsedEndDate = dayjs(endDate, "DD-MM-YYYY");
    
        if (!parsedStartDate.isValid() || !parsedEndDate.isValid()) {
          setError("Please select valid start and end dates");
          return;
        }
    
        if (parsedEndDate.isBefore(parsedStartDate)) {
          setError("End date cannot be less than start date");
          return;
        }
    
        const formattedStartDate = parsedStartDate.format("DD-MM-YYYY");
        const formattedEndDate = parsedEndDate.format("DD-MM-YYYY");
    
        let expDateKey;
        if (taskType === "Calibration") {
          expDateKey = "calib_due_date";
        } else if (taskType === "Warranty") {
          expDateKey = "warranty_exp_date";
        } else if (taskType === "AMC/CMC") {
          expDateKey = "cmc_amc_due_date";
        }
    
        let data = {
          hospid: userIds.hospid,
          userid: userIds.userid,
          hospname: userIds.hospname,
          hospcity: userIds.city,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          expdate: getId?.[expDateKey],
          assetid: getId?.asset_id,
          username: userIds.username,
          branchid: userBranch.id,
        };
    
        let dispatchAction;
        if (taskType === "Calibration") {
          dispatchAction = CalibrationTaskCompleted;
        } else if (taskType === "Warranty") {
          dispatchAction = WarrantyTaskCompleted;
        } else if (taskType === "AMC/CMC") {
          dispatchAction = AmcCmcTaskCompleted;
        }
    
        dispatch(dispatchAction(data))
          .then(() => {
            const requestData = {
              hospid: userIds?.hospid,
              userid: userIds?.userid,
              branchid: userBranch?.id,
              month:
                selectedMonth < 9 ? `0${selectedMonth + 1}` : selectedMonth + 1,
              year: selectedYear,
              depart_ids: userAccess.departmentids,
            };
            dispatch(calender(requestData));
    
            // Reset the dates and close the modal
            setStartDate(null);
            setEndDate(null);
            setIsPopoverVisible(false);
    
            // Open the success modal
            setIsSuccessfullModalOpen(true);
          })
          .catch((error) => {
            // Handle error here if necessary
          });
      } else {
        setError("Please select both start and end dates");
      }
    };
    
  
    useEffect(() => {
      setTaskData(eventData);
    }, [eventData]);



    
  const [visibleEvents, setVisibleEvents] = React.useState(() =>
    eventData?.map(() => true)
  );

    const handleCloseEvent = (index) => { 
  console.log("Closing event at index:", index); // Debug log
  setVisibleEvents((prev) =>
    prev.map((visible, idx) => (idx === index ? false : visible))
  );
  setIsDataOPen(false);
  console.log("isDataOpen set to false"); // Debug log
};

  
const [hiddenItems, setHiddenItems] = useState([]);

const handleCloseButton = (assetId) => {
  setHiddenItems((prevState) => {
    if (prevState.includes(assetId)) {
      return prevState.filter((id) => id !== assetId);
    } else {
      return [...prevState, assetId];
    }
  });
};

    return (
      <>
        {isDataOpen && (
          <Box>
            {validEventData.map((item, index) => (
              <Stack key={index} sx={{ padding: "10px", display: hiddenItems.includes(item.asset_id) ? "none" : "block",
              }}>
                 <Stack sx={{display:"flex",alignItems:"end",justifyContent:"end"}}>
                 <CloseIcon
                onClick={() => handleCloseButton(item.asset_id)}
                sx={{
                  cursor: "pointer",
                  color: "#FF4B4B",
                  "&:hover": { color: "#FF0000" },
                }}
              />



              </Stack>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "17px",
                    fontWeight: 700,
                    marginBottom: "10px",
                  }}
                >
                  {taskTitle} {index + 1}
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: "none", marginBottom: "10px" }}
                >
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "5px 0",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Asset Name
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "5px 0",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          {item?.asset_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "5px 0",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Code
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "5px 0",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          {item?.asset_code}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "5px 0",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Department
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "5px 0",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          {item?.department}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
  
                {userAccess.role !== "Guest" && (
                  <Stack spacing={2}>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        gap: "10px",
                      }}
                    >
                      {item.req_status === "NA" || item.req_status === "" ? (
                        <>
                          <TextField
                            type="number"
                            placeholder={
                              taskTitle === "Warranty"
                                ? "Extension"
                                : "Enter Year"
                            }
                            size="small"
                            inputProps={{
                              min: 0,
                              style: { textAlign: "center" },
                            }}
                            value={inputValues[item.asset_id] || ""}
                            onChange={(event) =>
                              handleInputChange(event, item.asset_id)
                            }
                            style={{ width: "100%", textAlign: "center" }}
                            helperText={durationError[item.asset_id] || ""}
                          />
                        </>
                      ) : item.req_status === "Requested" ? (
                        <TextField
                          type="number"
                          inputProps={{
                            min: 0,
                            style: { textAlign: "center" },
                          }}
                          placeholder={
                            taskTitle === "Warranty" ? "Extension" : "Enter Year"
                          }
                          size="small"
                          value={inputValues[item.asset_id] || ""}
                          onChange={(event) => {
                            const numericValue = event.target.value
                              .replace(/\D/, "")
                              .slice(0, 2);
                            handleInputChange(
                              {
                                ...event,
                                target: {
                                  ...event.target,
                                  value: numericValue,
                                },
                              },
                              item.asset_id
                            );
                          }}
                          style={{ width: "100%", textAlign: "center" }}
                        />
                      ) : null}
                      <Button
                        key={`button_${item.asset_id}`}
                        variant="outlined"
                        size="small"
                        onClick={(event) => {
                          handleButtonClick(item);
                          handleClicks(event);
                        }}
                        style={{
                          width: "100%",
                          textDecoration: "none",
                          border: "none",
                          color: "#000",
                          textTransform: "none",
                          background: "#F4F6F6",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>
                          {item.assigned_to !== "NA" &&
                          item.task_status !== "Completed" &&
                          selectedUsers[item.asset_id]?.username === undefined
                            ? item.assigned_to
                            : selectedUsers[item.asset_id]?.username !== undefined
                            ? selectedUsers[item.asset_id]?.username
                            : "Select User"}
                        </Typography>
                        <ExpandMoreIcon />
                      </Button>
                      {getUser && (
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleCloses}
                        >
                          {item.assigned_to === "NA" &&
                          selectedUsers[item.asset_id] ? (
                            <MenuItem disabled>
                              {selectedUsers[item.asset_id].username}
                            </MenuItem>
                          ) : (
                            <MenuItem disabled></MenuItem>
                          )}
  
                          {getUser?.map((user) => (
                            <MenuItem
                              key={user.userid}
                              onClick={() => {
                                handleSelectChange(
                                  { target: { value: user } },
                                  item.asset_id
                                );
                                handleCloses();
                              }}
                              disabled={item.assigned_to === user.username}
                              sx={{
                                "&:hover": { backgroundColor: "#d0d0d0" },
                                cursor:
                                  item.assigned_to === user.username
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              {user.username}
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    </Stack>
  
                    {isAnyTaskStatusNotEmpty &&
                      eventData.some(
                        (item) => item.task_status !== "Completed"
                      ) && (
                        <Box
                          sx={{
                            borderRadius: "5px",
                            margin: "auto",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: {
                              xl: "18px",
                              lg: "16px",
                              md: "14px",
                              sm: "12px",
                              xs: "10px",
                            },
                            height: "30px",
                            padding: "4px",
  
                            border:
                              item.task_status === "Not Completed"
                                ? "1px solid #FF4B4B"
                                : "white",
                            color:
                              item.task_status === "Not Completed"
                                ? " #FF4B4B"
                                : "white",
                          }}
                        >
                          <Typography> {item.task_status}</Typography>
                        </Box>
                      )}
  
                    {/* Request */}
                    {item.req_status === "NA" ||
                    (item.req_status === "Requested" &&
                      (item.assigned_to !== selectedUsers[item.asset_id] ||
                        item.duration !== inputValues[item.asset_id])) ? (
                 
                      <Button
                        onClick={() =>
                          handleRenewClick(
                            item.asset_id,
                            taskTitle === "Calibration"
                              ? "Calibration"
                              : taskTitle === "AMC/CMC"
                              ? "AMC_CMC"
                              : taskTitle === "Warranty"
                              ? "Warranty"
                              : ""
                          )
                        }
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          width: "100%",
                          background:
                            taskTitle === "Calibration"
                              ? "#4DDCE0"
                              : taskTitle === "Warranty"
                              ? "#4690FF"
                              : taskTitle === "AMC/CMC"
                              ? "#235275"
                              : "",
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: 700,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50px",
                          "&:hover": {
                            background:
                              taskTitle === "Calibration"
                                ? "#4DDCE0"
                                : taskTitle === "Warranty"
                                ? "#4690FF"
                                : taskTitle === "AMC/CMC"
                                ? "#235275"
                                : "",
                          },
                        }}
                      >
                        Request
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "none",
                          width: "100%",
                          // bgcolor: "#00A884",
                          background:
                            taskTitle === "Calibration"
                              ? "#4DDCE0"
                              : taskTitle === "Warranty"
                              ? "#4690FF"
                              : taskTitle === "AMC/CMC"
                              ? "#235275"
                              : "",
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: 700,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50px",
                          "&:hover": {
                            background: "Calibration"
                              ? "#4DDCE0"
                              : taskTitle === "Warranty"
                              ? "#4690FF"
                              : taskTitle === "AMC/CMC"
                              ? "#235275"
                              : "",
                          },
                        }}
                      >
                        Requested
                      </Button>
                    )}
  
                    {/* complete */}
                    {userIds.username === item.assigned_to ? (
                      <>
                        {item.task_status === "Not Completed" ||
                        (item.req_status === "Requested" &&
                          (item.assigned_to !== selectedUsers[item.asset_id] ||
                            item.duration !== inputValues[item.asset_id])) ? (
                          <div>
                            <Button
                              onClick={(event) => {
                                setIsPopoverVisible(true);
                                setPopoverAnchorEl(event.currentTarget);
                                handleClick(event, item);
                              }}
                              variant="contained"
                              sx={{
                                bgcolor: "#1746A2",
                                textTransform: "none",
                                width: "100%",
                                borderRadius: "50px",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Complete
                            </Button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  
                  </Stack>
                )}
              </Stack>
            ))}
          </Box>
        )}
  
        <CalenderModal
          open={successDialogOpen}
          onClose={handleClose2}
          modalClose={handleClose}
        />
   
  
        <Modal
          keepMounted
          open={isPopoverVisible}
          anchorEl={popoverAnchorEl}
          onClose={() => setIsPopoverVisible(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <ModalBox>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <Typography
                sx={{ paddingLeft: "10px", fontSize: "17px", fontWeight: 700 }}
              >
                Warranty
              </Typography>
              <IconButton onClick={() => setIsPopoverVisible(false)}>
                <CloseIcon sx={{ fill: "#000" }} />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "120px",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                position: "relative",
                top: "15px",
                padding: "10px",
              }}
            >
              <YesDateBox>
                <DateBox padding="5px" bgcolor="#EFF5FE">
                  <CustomText fontWeight="600"> Start Date</CustomText>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div>
                      <DatePicker
                        format="DD/MM/YYYY"
                        sx={{ bgcolor: "white" }}
                        minDate={
                          getId && dayjs(getId.calib_due_date, "DD-MM-YYYY")
                        }
                        slotProps={{
                          textField: {
                            error: false,
                            size: "small",
                          },
                        }}
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </div>
                  </LocalizationProvider>
                </DateBox>
  
                <DueDateBox>
                  <DateBox padding="5px" bgcolor="#EFF5FE">
                    <CustomText fontWeight="600"> End Date</CustomText>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div>
                        <DatePicker
                          format="DD/MM/YYYY"
                          minDate={
                            // getId && dayjs(newCalibrationExpDate, "DD-MM-YYYY")
                            getId && dayjs(newExpDate, "DD-MM-YYYY")
  
                          }
                          value={endDate}
                          sx={{ bgcolor: "white" }}
                          slotProps={{
                            textField: {
                              error: false,
                              size: "small",
                            },
                          }}
                          onChange={handleEndDateChange}
                        />
                      </div>
                    </LocalizationProvider>
                  </DateBox>
                </DueDateBox>
              </YesDateBox>
              <Divider
                sx={{ width: "100%", position: "absolute", top: "180px" }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "150px", borderRadius: "22px", fontWeight: 600 }}
                onClick={()=>handleSubmitDate(
                  taskTitle === "Calibration"
                  ? "Calibration"
                  : taskTitle === "AMC/CMC"
                  ? "AMC/CMC"
                  : taskTitle === "Warranty"
                  ? "Warranty"
                  : ""
                )}
              >
                Complete
              </Button>
            </Stack>
          </ModalBox>
        </Modal>
  
        {/* Successfull modal */}
  
        <Modal
          keepMounted
          open={isSuccessfullModalOpen}
          // anchorEl={popoverAnchorEl}
          onClose={() => setIsSuccessfullModalOpen(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <ModalBox>
            <Stack
              sx={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                paddingRight: "10px",
              }}
            >
              <IconButton onClick={() => setIsSuccessfullModalOpen(false)}>
                <CloseIcon sx={{ fill: "#000" }} />
              </IconButton>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                gap: "90px",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                position: "relative",
                top: "15px",
                padding: "10px",
              }}
            >
              <Box>
                <img src={rightTickImage} />
                <Typography>Successful!</Typography>
                <Typography>
                  Your request has been successfully processed.
                </Typography>
              </Box>
              <Divider
                sx={{ width: "100%", position: "absolute", top: "180px" }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "150px", borderRadius: "22px", fontWeight: 600 }}
                onClick={() => {
                  setIsSuccessfullModalOpen(false);
                  setIsDataOPen(false);
                }}
              >
                Got it
              </Button>
            </Stack>
          </ModalBox>
        </Modal>
      </>
    );
  };
  
  export default CalenderTaskComponent;
  