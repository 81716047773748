import React from "react";
import { Modal, IconButton, Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { jsPDF } from "jspdf";
import dummyImage from "../images/download.png";
import vajraImage from "../images/vajraNewLogo.jpg";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";

const ViewImageModal = ({
  imageUrl,
  handleClose,
  open,
  department,
  assetId,
  assestname,
  // assetLogo
}) => {
  const email = "www.vajra.software";
  const contact = "contact@vajra.software";

  const assetLogo = useSelector((store) => store?.data?.logoUrl?.logo_url);
  // const assetLogo = 'https://images.pexels.com/photos/3845129/pexels-photo-3845129.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'

  // const assetLogo = useSelector((store) => store?.data?.logoUrl?.logo_url);
  // console.log(assetLogo);

  const handleDownload = () => {
    // console.log(imageUrl);
    const pdf = new jsPDF("p", "mm", [130, 130]);

    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");

    const centerX = pdf.internal.pageSize.width / 2;

    pdf.setLineWidth(0.85);
    pdf.setDrawColor(0, 0, 0);
    // Asset ID section
    const assetIdLabel = "Asset Name";
    const assetIdLabelWidth = pdf.getTextWidth(assetIdLabel);
    pdf.text(assetIdLabel, centerX - assetIdLabelWidth / 70, 45);

    pdf.setFont("helvetica", "normal");
    const assetIdTextWidth = pdf.getTextWidth(assestname);
    pdf.text(assestname, centerX - assetIdTextWidth / 70, 55); // Set Y-position clearly below the label
    if (assetLogo !== "NA") {
      pdf.addImage(assetLogo, "PNG", 70, 1, 50, 25);
    }

    pdf.addImage(imageUrl, "JPEG", 2, 20, 60, 70);

    // console.log("image========", imageUrl);

    pdf.setLineWidth(0.85);
    pdf.setDrawColor(0, 0, 0);
    pdf.line(65, 68, pdf.internal.pageSize.width - 5, 68);

    pdf.setFont("helvetica", "bold");
    const departmentLabel = "Department";
    const departmentLabelWidth = pdf.getTextWidth(departmentLabel);
    pdf.text(departmentLabel, centerX - departmentLabelWidth / 70, 75);

    // Set font for department
    pdf.setFont("helvetica", "normal");

    const departmentText = department;
    const departmentLabelWidth1 = pdf.getTextWidth(departmentText);
    pdf.text(departmentText, centerX - departmentLabelWidth1 / 70, 85);
    const maxWidth = 70; // Custom width for department section
    let currentY = 82;

    const words = departmentText.split(" ");
    let currentLine = "";

    pdf.addImage(vajraImage, "PNG", 80, 100, 50, 30);

    pdf.setFontSize(12);
    pdf.setFont("helvetica", "bold");
    pdf.text(email, 5, 110);
    pdf.text(contact, 5, 115);

    pdf.setLineWidth(0.85);
    pdf.setDrawColor(0, 0, 0);
    pdf.line(1, 100, 60, 100);

    pdf.save(`${assetId}.pdf`);
    handleClose();
  };

  const printImage = (imageSrc) => {
    if (imageSrc) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        `<img src="${imageSrc}" onload="window.print();window.close()" />`
      );
      printWindow.document.close();
    } else {
      // console.log("Image not downloaded yet.");
    }
  };

  const handleQRCodeDownload = () => {
    if (!imageUrl) {
      console.error("Image URL is not provided.");
      return;
    }

    const link = document.createElement("a");
    link.href = imageUrl; // Image URL of the QR code
    link.download = `${assetId}.jpg`; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="400px"
        height="auto"
        sx={{ background: "white" }}
        padding="20px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>

        <Box display="flex" justifyContent="space-between" width="100%">
          <Box textAlign="center">
            <img
              src={imageUrl}
              alt="QR Code"
              style={{ width: "130px", height: "130px" }}
            />
            <hr />
            <Typography>{email}</Typography>
            <Typography>{contact}</Typography>
          </Box>

          <Box textAlign="center" sx={{ width: "40%" }}>
            {assetLogo === "NA" || assetLogo === undefined ? (
              <PersonIcon sx={{ fontSize: "50px" }} />
            ) : (
              <img
                src={assetLogo}
                width="100px"
                alt="Hospital Logo"
                height="50px"
              />
            )}
            <Typography sx={{ fontWeight: "bold" }}>Asset Name</Typography>
            <Typography>{assestname}</Typography>
            <hr />
            <Typography sx={{ fontWeight: "bold" }}>Department</Typography>
            <Typography>{department}</Typography>
            <img src={vajraImage} width="80px" alt="Vajra Logo" />
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          gap="20px"
          sx={{ marginTop: "15px" }}
        >
          <Button onClick={handleDownload} variant="contained">
            Print
          </Button>
          <Button onClick={handleQRCodeDownload} variant="contained">
            Download
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewImageModal;
