

import React from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import InputBase from "@mui/material/InputBase";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  IconButton,
  Stack,
  Typography,
  styled,
  Tooltip,
  Checkbox,
  Button,
} from "@mui/material";
import { useState } from "react";
import {
  bulkAssetDelete,
  deleteAsset,
  departmentAssetDetails,
  retrieveAsset,
} from "../redux/nbmData/action";
import { useTheme } from "@emotion/react";
import TableSort from "../components/TableSort";
import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";
import BulkUploadAssetPopUp from "./BulkUploadAssetPopUp";
import BulkDeleteDeleteModal from "../components/BulkDeleteModal";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "10px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
  padding: "10px",
}));
const TopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  background: "white",
  height: "50px",
  padding: "10px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const DepartmentAssetDetails = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const [filteredData, setFilteredData] = useState([]);

  const allAssets = useSelector((store) => store.data.departmentAssetDetails);
  const { id } = useParams();
  const deletedStatus = useSelector((store) => store.data.bulkAssetDelete);
  const [successToggle, setSuccessToggle] = useState(false);
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [getId, setGetId] = useState("");
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const deleted = useSelector((store) => store.data.deleteAsset);
  const department = [...new Set(allAssets?.map((item) => item.department))];
  const status = [...new Set(allAssets?.map((item) => item.status))];

  const amccmc = [...new Set(allAssets?.map((item) => item.cmcamc))];

  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);

  const openSort = Boolean(anchorElSort);
  const openFilter = Boolean(anchorElFilter);

  const [toggle, setToggle] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAmcCmc, setSelectedAmcCmc] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const [anchorElSort2, setAnchorElSort2] = React.useState(null);
  const [filterChanged, setFilterChanged] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);
  const [anchorElFilter2, setAnchorElFilter2] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const departmentAsset = localStorage.getItem("selectedDepartname");
  const handleSelectAllClick = () => {
    const allAssetIds = data?.map((item) => item.assetid);

    if (selectAll) {
      // Deselect all
      setSelectedAssetIds([]);
    } else {
      // Select all
      setSelectedAssetIds(allAssetIds);
    }

    setSelectAll(!selectAll);
  };

  const handleSelectRowClick = (assetId) => {
    const selectedIndex = selectedAssetIds.indexOf(assetId);
    let newSelected = [];

    if (selectedIndex === -1) {
      // Asset is not selected, add it to the array
      newSelected = [...selectedAssetIds, assetId];
    } else {
      // Asset is selected, remove it from the array
      newSelected = [
        ...selectedAssetIds.slice(0, selectedIndex),
        ...selectedAssetIds.slice(selectedIndex + 1),
      ];
    }

    setSelectedAssetIds(newSelected);
  };

  useEffect(() => {
    // Update filteredData whenever data or filterValue changes
    const newFilteredData = filterValue
      ? allAssets.filter((row) => row.status === filterValue)
      : allAssets;
    setData(newFilteredData);
  }, [filterValue]);

  const handleClickFilter2 = (event) => {
    setAnchorElFilter2(event.currentTarget);
  };

  const handleCloseFilter2 = () => {
    setAnchorElFilter2(null);
  };

  const handleFilter2 = (status) => {
    setFilterValue(status);
    setAnchorElFilter2(null);
    setPage(0);
    setFilterChanged(true);
  };

  const text = "asset";

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };

  const handleCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };

  const handleButtonClick = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
    } else {
      navigate("/bulk-upload-asset");
    }
  };
  const handleButtonClick2 = () => {
    if (ids.subs_plan === "Basic") {
      setOpenModal(true);
      handleCloseSort2();
    } else {
      downloadCsvFile();
      handleCloseSort2();
    }
  };
  const handleButtonClick3 = () => {
    if (ids.subs_plan === "Basic" && allAssets?.length >= 50) {
      setOpenModal(true);
    } else {
      navigate("/add-asset");
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openSort2 = Boolean(anchorElSort2);

  const handleChangePage = (event, newPage) => {
    if (filterChanged) {
      setPage(0);
      setFilterChanged(false);
    } else {
      const filteredIncidents = filterProducts();
      const newPageIndexStart = newPage * rowsPerPage;
      const newPageIndexEnd = newPageIndexStart + rowsPerPage;
      const currentPageIncidents = filteredIncidents.slice(
        newPageIndexStart,
        newPageIndexEnd
      );

      const newPageCount = Math.ceil(filteredIncidents.length / rowsPerPage);
      if (newPage >= newPageCount) {
        newPage = newPageCount - 1;
      }

      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilterChanged(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setFilterChanged(true);
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategories((prevCategories) =>
      prevCategories?.includes(category)
        ? prevCategories?.filter((c) => c !== category)
        : [...prevCategories, category]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus((prevCategories) =>
      prevCategories?.includes(status)
        ? prevCategories?.filter((c) => c !== status)
        : [...prevCategories, status]
    );
    setPage(0);
    setFilterChanged(true);
  };
  const handleAmcCmcChange = (e) => {
    const amccmc = e.target.value;
    setSelectedAmcCmc((prevCategories) =>
      prevCategories?.includes(amccmc)
        ? prevCategories?.filter((c) => c !== amccmc)
        : [...prevCategories, amccmc]
    );
    setPage(0);
    setFilterChanged(true);
  };

  const filterProducts = () => {
    let filteredProducts = [...data];

    filteredProducts = filteredProducts?.filter(
      (product) =>
        product?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.serialno.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product?.assetcode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedCategories?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedCategories?.includes(product.department)
      );
    }

    if (selectedStatus?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedStatus?.includes(product.status)
      );
    }
    if (selectedAmcCmc?.length > 0) {
      filteredProducts = filteredProducts?.filter((product) =>
        selectedAmcCmc?.includes(product.cmcamc)
      );
    }
    return filteredProducts;
  };

  const filteredProducts = filterProducts();

  const open = Boolean(anchorEl);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);

    setGetId(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleSort2 = (event) => {
    setAnchorElSort2(event.currentTarget);
  };
  const handleFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorElSort(null);
  };
  const handleCloseSort2 = () => {
    setAnchorElSort2(null);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };
  const sortHighToLow = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
        const dateA = new Date(a.cmcamcdate.split("-").reverse().join("-"));
        const dateB = new Date(b.cmcamcdate.split("-").reverse().join("-"));
        return dateA - dateB;
      } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
        return 1;
      } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  const sortLowToHigh = () => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a.cmcamcdate !== "NA" && b.cmcamcdate !== "NA") {
        const dateA = new Date(a.cmcamcdate.split("-").reverse().join("-"));
        const dateB = new Date(b.cmcamcdate.split("-").reverse().join("-"));
        return dateB - dateA;
      } else if (a.cmcamcdate === "NA" && b.cmcamcdate !== "NA") {
        return 1;
      } else if (a.cmcamcdate !== "NA" && b.cmcamcdate === "NA") {
        return -1;
      } else {
        return 0;
      }
    });

    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };

  useEffect(() => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      branchid: userBranch.id,
      depart_ids: userAccess.departmentids,
    };

    dispatch(retrieveAsset(data, token));
    setToggle(false);
  }, [dispatch, deleted, toggle, deletedStatus]);

  const handleDelete = (e, item, id) => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetid: getId,
      branchid: userBranch.id,
    };
    setToggle(true);
    setSuccessToggle(true);
    dispatch(deleteAsset(data, token));
    setData(allAssets?.filter((el) => el?.assetid !== getId));
    handleCloseDeleteModal();
  };

  const handleEdit = () => {
    navigate(`/edit-asset/${getId}`);
  };

  useEffect(() => {
    if (allAssets && allAssets?.length > 0) {
      setData(allAssets);
    }
  }, [allAssets, deleted, deletedStatus]);

  const handleDeleteById = () => {
    handleClose();
    handleOpenDeleteModal();
    setToggle(true);
  };
  const sortDataByKey = (key, ascending) => {
    const sortedData = [...allAssets].sort((a, b) => {
      const valueA = a[key].trim().toLowerCase();
      const valueB = b[key].trim().toLowerCase();
      return ascending
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
    setData(sortedData);
    handleCloseSort();
    setPage(0);
    setFilterChanged(true);
  };
  const jsonToCsv = (data) => {
    const csvContent = [];
    const headers = Object.keys(data[0]);

    csvContent.push(headers.join(","));

    data.forEach((item) => {
      const row = headers.map((header) => item[header]);
      csvContent.push(row.join(","));
    });

    const csvString = csvContent.join("\n");

    return csvString;
  };

  const downloadCsvFile = () => {
    const csvData = jsonToCsv(allAssets);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    handleCloseSort2();
  };

  const extractProperties = (item, column) => {
    switch (column) {
      case "name":
        return item.name;
      case "date":
        return item.cmcamcdate;
      default:
        return "";
    }
  };

  const { handleSortTable, sortDirection } = TableSort(
    data,
    setData,
    extractProperties,
    setPage,
    setFilterChanged
  );

  const handleBulkDelete = () => {
    let data = {
      hospid: ids.hospid,
      userid: ids.userid,
      assetids: selectedAssetIds,
      branchid: userBranch.id,
    };
    dispatch(bulkAssetDelete(data));
    setToggle(true);
    setOpenBulkDeleteModal(false);
    setSuccessToggle(true);
  };
  useEffect(() => {
    return () => {
      localStorage.removeItem("selectedDepartname");
    };
  }, []);

  useEffect(() => {
    setFilteredData([]);
    const isAllPresent =
      userAccess.departmentids.includes("all") ||
      userAccess.departments.includes("All");
    console.log("isAllPresent", isAllPresent);

    const isIdMatched = id === "all" || userAccess.departmentids.includes(id);
    console.log("isIdMatched", isIdMatched);

    if (isAllPresent || isIdMatched) {
      let data = {
        hospid: ids.hospid,
        userid: ids.userid,
        deptid: id,
        branchid: userBranch.id,
      };

      dispatch(departmentAssetDetails(data, token));
    } else {
      let data = {
        hospid: ids.hospid,
        userid: ids.userid,
        deptid: "",
        branchid: userBranch.id,
      };

      dispatch(departmentAssetDetails(data, token));
    }
  }, [dispatch, deleted, successToggle]);

  console.log("idddd", id);

  const handleSuccessCloseModal = () => {
    setSuccessToggle(false);
  };

  useEffect(() => {
    // Update filteredData whenever data or filterValue changes
    const newFilteredData = filterValue
      ? allAssets.filter((row) => row.status === filterValue)
      : allAssets;
    setData(newFilteredData);
  }, [filterValue]);


  useEffect(() => {
    if (allAssets && allAssets?.length > 0) {
      setData(allAssets);
    }
  }, [allAssets, deleted, deletedStatus]);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };
  console.log("filter", filteredData);

  const [allCounts, setAllCounts] = useState({});
  const handleOpenModal = () => setOpenModal(true);
  const handleAssetAddModal = () => {
    if (allCounts?.department === 0) {
      handleOpenModal();
    } else {
      navigate("/add-asset");
    }
  };

  /////////////////////////////////////////////

  useEffect(() => {
    filterData();
  }, [filterValue, allAssets]);

  const filterData = () => {
    const newFilteredData = filterValue
      ? allAssets.filter((row) => row.status === filterValue)
      : allAssets;
    setData(newFilteredData);
  };

  //////////////////////////////////////////////////

  const isGuest = userAccess && userAccess.role === "Guest";

  return (
    <Box
      display="flex"
      flexDirection="column"
      //   justifyContent="space-between"
      alignItems="stretch"
      gap="10px"
      height="90vh"
      padding="16px"
    >
      <TopContainer>
        <Label variant="subtitle1" sx={{ fontSize: "20px", fontWeight: 700 }}>
          {allAssets[0]?.department || "No Department"}
        </Label>
      </TopContainer>
      <Box display="flex" flexDirection="column" gap="10px">
        <Stack
          justifyContent="space-between"
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }}
        >
          <Label variant="h6">
            Assets.
            <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
          </Label>
          {selectedAssetIds?.length > 0 && (
            <IconButton
              onClick={handleOpenBulkDeleteModal}
              sx={{
                bgcolor: "#1B2535",
                width: "27px ",
                height: "27px",
                "&:hover": {
                  backgroundColor: "#1B2535",
                  color: "white",
                },
              }}
            >
              <DeleteIcon style={{ color: "white", fontSize: "16px" }} />
            </IconButton>
          )}
          <Button
            onClick={handleAssetAddModal}
            sx={{
              bgcolor: "#1B2535",
              width: "auto ",
              height: "27px",
              textDecoration: "none",
              borderRadius: "20px",
              borderRight: "20px",
              color: "white",
              "&:hover": {
                backgroundColor: "#1B2535",
                color: "white",
              },
            }}
          >
            Add Asset <AddIcon style={{ color: "white", fontSize: "20px" }} />
          </Button>
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          gap="10px"
          sx={{
            display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
          }}
        >
          <Box flex="2">
            <SortSearchFilter
              data={data}
              setData={setData}
              filteredData={filteredData}
              s
              setFilteredData={setFilteredData}
              heading={"My Orders"}
              Filter1="department"
              Filter2="cmcamc"
              FilterName1="Department"
              FilterName2="AMC/CMC Status"
              sortDate="cmcamcdate"
              dueDate=""
              sortPrice=""
              name="name"
              csvName="All Assets"
            />
          </Box>

          <Box flex="1">
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-around"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}
            >
              {selectedAssetIds?.length > 0 && (
                <IconButton
                  onClick={!isGuest ? handleOpenBulkDeleteModal : undefined}
                  sx={{
                    bgcolor: "#1B2535",
                    width: "27px ",
                    height: "27px",
                    "&:hover": {
                      backgroundColor: "#1B2535",
                      color: "white",
                    },
                  }}
                >
                  <DeleteIcon style={{ color: "white", fontSize: "16px" }} />
                </IconButton>
              )}
              <Button
                onClick={!isGuest ? handleOpenBulkDeleteModal : undefined}
                sx={{
                  bgcolor: "#1B2535",
                  width: "auto ",
                  height: "27px",
                  textDecoration: "none",
                  borderRadius: "20px",
                  borderRight: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1B2535",
                    color: "white",
                  },
                }}
              >
                Add Asset{" "}
                <AddIcon style={{ color: "white", fontSize: "20px" }} />
              </Button>

              <BulkUploadAssetPopUp />
            </Stack>
          </Box>
        </Box>

        <Box flex="1" overflow="auto">
          {/* Table */}
          <TableContainer
            component={Paper}
            style={{
              height: "calc(100vh - 200px)",
              boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
            }}
          >
            {filteredData.length > 0 ? (
              <Table stickyHeader>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableHeading>
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selectedAssetIds.length > 0 &&
                          selectedAssetIds.length < data?.length
                        }
                        checked={selectedAssetIds.length === data?.length}
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all assets",
                        }}
                      />
                    </TableHeading>
                    <TableHeading>Asset Name</TableHeading>
                    <TableHeading>Asset Code</TableHeading>
                    <TableHeading>Brand</TableHeading>
                    <TableHeading>Department</TableHeading>
                    <TableHeading sx={{ textAlign: "center" }}>
                      Status
                    </TableHeading>
                    <TableHeading>Date</TableHeading>

                    <TableHeading>AMC/CMC</TableHeading>
                  </TableRow>
                </TableHead>
                {/* Table Body with Scrollbar */}
                <TableBody style={{ overflowY: "scroll" }}>
                  {filteredData.map((item, index) => (
                    <TableRow key={item.assetid}>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}}>
                        <Checkbox
                          checked={selectedAssetIds.includes(item.assetid)}
                          onChange={() => handleSelectRowClick(item.assetid)}
                        />
                      </TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.name}>{item.name}</Tooltip>
                      </TableCells>

                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.assetcode}>
                          {item.assetcode}
                        </Tooltip>
                      </TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.brand}>{item.brand}</Tooltip>
                      </TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        <Tooltip title={item.department}>
                          {item.department}
                        </Tooltip>
                      </TableCells>

                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal', textAlign: "center",
                          color: "rgba(70, 144, 255, 1)",}}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                  
                      >
                        {item?.status !== "Working" ? (
                          <Button
                            size="small"
                            sx={{
                              borderRadius: "35px",
                              textTransform: "none",
                              width: "100px",
                              height: "25px",
                              bgcolor: "rgba(255, 107, 0, 1)",
                              "&:hover": {
                                bgcolor: "rgba(255, 107, 0, 1)",
                              },
                            }}
                            variant="contained"
                          >
                            {item.status}
                          </Button>
                        ) : (
                          <>{item.status}</>
                        )}
                      </TableCells>

                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        {item.cmcamcdate}
                      </TableCells>

                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}
                      >
                        {item.cmcamc}
                      </TableCells>

                  
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                No asset found
              </Typography>
            )}
          </TableContainer>
        </Box>
      </Box>
      <BulkDeleteDeleteModal
        open={openBulkDeleteModal}
        onClose={handleCloseBulkDeleteModal}
        onDelete={handleBulkDelete}
        text={text}
        count={selectedAssetIds?.length}
      />
    </Box>
  );
};

export default DepartmentAssetDetails;
