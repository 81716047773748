
import { useState } from "react";
import { useEffect } from "react";




const TableSort = (data, setData, extractProperties,setPage,
  setFilterChanged,) => {
    
  const [sortDirection, setSortDirection] = useState({
    name: "asc",
    date: "asc",
    duedate: "asc",
     assets:"asc"
  });

  const handleSortTable = (column) => {
    const newDirection = sortDirection[column] === "asc" ? "desc" : "asc";
    setSortDirection((prevDirection) => ({
      ...prevDirection,
      [column]: newDirection,
    }));

    const sortedData = [...data];

    sortedData.sort((a, b) => {
      const propertyA = extractProperties(a, column).trim();
      const propertyB = extractProperties(b, column).trim();

      if (
        (column === "date" || column === "duedate") &&
        propertyA !== "NA" &&
        propertyB !== "NA"
      ) {
        const dateA = propertyA
          ? new Date(propertyA.split("-").reverse().join("-"))
          : null;
        const dateB = propertyB
          ? new Date(propertyB.split("-").reverse().join("-"))
          : null;

        if (dateA === null && dateB === null) {
          return 0;
        } else if (dateA === null) {
          return 1;
        } else if (dateB === null) {
          return -1;
        } else {
          return newDirection === "asc" ? dateA - dateB : dateB - dateA;
        }
      } else if (propertyA === "NA" && propertyB === "NA") {
        return 0;
      } else if (propertyA === "NA") {
        return 1;
      } else if (propertyB === "NA") {
        return -1;
      } else if (column === "name") {
        return newDirection === "asc"
          ? propertyB.localeCompare(propertyA)
          : propertyA.localeCompare(propertyB);
      } 
      else if (column === "assets") {
        return newDirection === "asc"
          ? propertyB.localeCompare(propertyA)
          : propertyA.localeCompare(propertyB);
      } 
      else {
        return 0;
      }
    });

    setData(sortedData);
    setPage(0);
    setFilterChanged(true);
  };

  return { handleSortTable, sortDirection };
};

export default TableSort;
