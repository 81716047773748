import React,  { useRef } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";


import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Stack,
  Typography,
  styled,
  Button,
  Tooltip,
  Divider,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { AssetHistoryRetrieve } from "../redux/data/action";

import { useEffect } from "react";
import SortSearchFilter from "../components/SortSearchFilter";

import AssetMovement from "./AssetMovement";

const TableCells = styled(TableCell)(({ theme }) => ({
  maxWidth: "11ch",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textDecoration: "none",
  borderBottom: "none",
  cursor: "pointer",
  padding: "15px",
  fontWeight: 500,
}));
const TableHeading = styled(TableCell)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  whiteSpace: "nowrap",
}));

const MainFlex = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  gap: "10px",
  height: "85vh",
  width: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    height: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const AllAssetHistory = () => {
  const assetHistoryData = useSelector(
    (store) => store?.data?.assetHistoryRetrieveData
  );
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const token = localStorage.getItem("userToken");
  let ids = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [showMovementBox, setShowMovementBox] = useState(false);
  const response = useSelector((store) => store.data.assetHistoryData);

  const handleAddMovementClick = () => {
    setShowMovementBox(true);
  };

  useEffect(() => {
    let data = {
      hospid: ids?.hospid,
      userid: ids?.userid,
      branchid: userBranch?.id,
    };
    dispatch(AssetHistoryRetrieve(data, token));
  }, [dispatch, response]);

  useEffect(() => {
    if (assetHistoryData && assetHistoryData?.length > 0) {
      setData(assetHistoryData);
    }
  }, [assetHistoryData]);

  const handleCloseDepart = () => {
    setShowMovementBox(false);
  };
  const buttonRef = useRef(null);
  const handleAddCircleIconClick = () => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: 'smooth' }); // Scrolls to the button
    }
  };
  return (
    <Stack sx={{ padding: "10px" }}>
      <MainFlex>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          style={{ width: "100%" }}>
          <Stack
            justifyContent="space-between"
            flexDirection={"row"}
            alignItems={"center"}
            sx={{ display: { xs: "flex", sm: "flex", lg: "none" } }}>
            <Label variant="h6">
              Assets history.
              <span style={{ color: "#4690FF" }}> {`${data?.length}`}</span>
            </Label>
            <IconButton>
              <AddCircleIcon
                style={{ color: "#4690FF" }}
                onClick={handleAddCircleIconClick}
              />
            </IconButton>
          </Stack>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="stretch"
            gap="10px">
            <Stack
              flexDirection={"row"}
              height="50px"
              justifyContent={"space-between"}
              alignItems={"center"}
              padding="16px"
              gap={"10px"}
              sx={{
                display: { xs: "none", sm: "none", lg: "flex" },
                bgcolor: "white",
                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
              }}>
              <Typography>{`Total : ${data?.length}`}</Typography>
            </Stack>
            <Box flex="2">
              <SortSearchFilter
                data={data}
                setData={setData}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                Filter1="p_depart"
                Filter2="to_depart"
                FilterName1="Prev Depart"
                FilterName2="Current Depart"
                sortDate="date"
                sortPrice=""
                dueDate=""
                name="assetname"
                csvName="Assets Movements"
              />
            </Box>
          </Box>
          <Stack
            style={{
              width: "100%",
              height: "100%",

              bgcolor: "white",
            }}
            overflow="auto"
            flexDirection={"column"}>
            {/* Table */}
            <TableContainer
              component={Paper}
              style={{
                height: "calc(90vh - 200px)",

                boxShadow: "0px 0px 15px 0px rgba(219, 231, 229, 1)",
                width: "100%",
              }}>
                {filteredData.length > 0 ? <Table stickyHeader>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableHeading>Asset Name</TableHeading>
                    <TableHeading>Asset Code</TableHeading>
                    <TableHeading>Prev Depart</TableHeading>
                    <TableHeading>Curr Depart</TableHeading>

                    <TableHeading>Date</TableHeading>
                    <TableHeading>Time </TableHeading>
                    <TableHeading>User</TableHeading>
                  </TableRow>
                </TableHead>
                {/* Table Body with Scrollbar */}
                <TableBody style={{ overflowY: "scroll" }}>
                  {filteredData.map((item, index) => (
                    <TableRow>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal',textDecoration: "none" }}
                        component={Link}
                        to={`/asset-detail/${item.assetid}`}>
                        <Tooltip title={item.assetname}>
                          {item.assetname}
                        </Tooltip>
                      </TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}} component={Link}
                        to={`/asset-detail/${item.assetid}`}>{item?.assetcode}</TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}} component={Link}
                        to={`/asset-detail/${item.assetid}`}>{item?.p_depart}</TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}} component={Link}
                        to={`/asset-detail/${item.assetid}`}>{item?.to_depart}</TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}} component={Link}
                        to={`/asset-detail/${item.assetid}`} style={{ whiteSpace: "nowrap" }}>
                        {item?.date}
                      </TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}} component={Link}
                        to={`/asset-detail/${item.assetid}`} style={{ whiteSpace: "nowrap" }}>
                        {item?.time}
                      </TableCells>
                      <TableCells sx={{textOverflow:"clip",whiteSpace:'normal'}} component={Link}
                        to={`/asset-detail/${item.assetid}`} style={{ whiteSpace: "nowrap" }}>
                        {item?.username}
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>: <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                  }}
                >
                  No movement data found.
                </Typography>}
              
            </TableContainer>

            {!showMovementBox && (
              <Stack
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  bgcolor: "white",
                  gap: "20px",
                  // border:'1px solid red'
                }}>
                <Divider />
                <Stack
                 ref={buttonRef}
                  sx={{
                    bgcolor: "white",
                    alignItems: "flex-end",
                    padding: "10px",
                    justifyContent: "column",
                    //  border:'1px solid red'
                  }}>
                  <Button
                    onClick={handleAddMovementClick}
                    variant="contained"
                    sx={{
                      borderRadius: "22px",
                      background: "rgba(70, 144, 255, 1)",
                      // marginRight: "20px",
                    }}>
                    Add movements <AddIcon />
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
        {showMovementBox && (
          <AssetMovement handleCloseDepart={handleCloseDepart} />
        )}
      </MainFlex>
    </Stack>
  );
};

export default AllAssetHistory;
