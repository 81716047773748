import {
  Box,
  Button,
  Typography,
  styled,
  FormHelperText,
  Stack,
  TextField,
  MenuItem,
  Snackbar,
  Divider,
  Input,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccesoryUploadImageHistory,
  deleteImageAddAccessoryHistory,
  profile,
  profileEdit,
} from "../redux/data/action";
import { useState } from "react";
import MuiAlert from "@mui/material/Alert";
import SuccessPopUp from "../components/SuccessPopUp";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuid } from "uuid";
const MainDiv = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));
const InnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)",
  width: "70%",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "start",
  padding: "10px",
  gap: "10px",
  
  [theme.breakpoints.down("sm")]: {
    width: "auto", 
  },
}));


const TextContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const FormInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  bgcolor: "rgba(244, 246, 246, 1)",
  "& label.Mui-focused": {
    color: "rgba(166, 166, 166, 1)",
    bgcolor: "rgba(244, 246, 246, 1)",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "rgba(244, 246, 246, 1)",
  },
  "&:hover": {
    backgroundColor: theme.palette.background.paper, // Set hover background color
  },
  "& fieldset": { border: "none" },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  justifyItems: "space-between",
  width: "100%",
  alignItems: "center",
  columnGap: "20px",
  rowGap: "20px",
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px',
  },
}));
const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width:"100%"

}));

const UploadContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "70%",
  paddingBottom: "40px",
}));

const BtnWrapper2 = styled(Stack)(({ theme }) => ({
  display: "flex",
  // width: "1030px",
  flexDirection: "row",
  gap: "2%",
  marginBottom: "2%",
  borderRadius: "15px",
  alignItems: "center",
  justifyContent: "end",
  height: "100px",
}));
const Label = styled(Typography)(({ theme }) => ({
  color: "rgba(27, 37, 53, 1)",
  fontWeight: 700,
}));

function Profile() {
  const profileData = useSelector((store) => store?.data?.profile);
  const token = localStorage.getItem("userToken");
  let userIds =
    JSON.parse(sessionStorage.getItem("userData")) ||
    JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();

  const [nameError, setNameError] = useState("");
  const [isMobileNumberError, setIsMobileNumberError] = useState("");
  const [isZipError, setIsZipError] = useState("");
  const [isStateError, setIsStateError] = useState("");
  const [isCityError, setIsCityError] = useState("");
  const [isAddressError, setIsAddressError] = useState("");
  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState("");

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [address, setAddress] = useState("");

  const [other, setOther] = useState("");

  const [hospitalType, setHospitalType] = useState(profileData?.hosptype);

  const [hospitalTypeError, setHospitalTypeError] = useState("");
  const [otherError, setOtherError] = useState("");
  const dispatch = useDispatch();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [fileUrlHistory, setFileUrlHistory] = useState([]);
  const [isPhotoErrorHistory, setIsPhotoErrorHistory] = useState(null);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/profile");
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (!e.target.value) {
      setNameError("Name is required");
    } else {
      setNameError("");
    }
  };

  const handleDesignation = (e) => {
    const value = e.target.value.trim();
    const regex = /^[A-Za-z0-9\- ]+$/;
    setDesignation(value);

    if (value === "" || regex.test(value)) {
      setDesignationError("");
    } else {
      setDesignationError("Wrong designation");
    }

    if (value === "") {
      setDesignationError("Please fill the field");
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.'-]+$/i;

    if (value === "") {
      setCity(value);
      setIsCityError("Please enter a valid city");
    } else if (value.trim() === "") {
      setCity(value);
      setIsCityError("Invalid city");
    } else if (regex.test(value)) {
      setCity(value);
      setIsCityError("");
    } else {
      setIsCityError("Invalid city");
    }
  };

  const handleStateChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-z ,.'-]+$/i;

    if (value === "") {
      setState(value);
      setIsStateError("Please enter a valid state");
    } else if (value.trim() === "") {
      setState(value);
      setIsStateError("Invalid state");
    } else if (regex.test(value)) {
      setState(value);
      setIsStateError("");
    } else {
      setIsStateError("Invalid state");
    }
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ,.'-]+$/;

    if (value === "") {
      setAddress(value);
      setIsAddressError("Please enter a valid address");
    } else if (value.trim() === "") {
      setAddress(value);
      setIsAddressError("Invalid address");
    } else if (regex.test(value)) {
      setAddress(value);
      setIsAddressError("");
    } else {
      setIsAddressError("Invalid address");
    }
  };
  const handlePinCodeChange = (e) => {
    const pinCode = e.target.value.replace(/\s/g, "");
    setZip(pinCode);

    const pinCodeRegex = /^\d{6}$/;

    if (!pinCode || !pinCodeRegex.test(pinCode)) {
      setIsZipError("Pin Code is not valid");
    } else {
      setIsZipError("");
    }
  };

  const handleOthers = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z ]+$/;
    setOther(value);

    if (value.trim() === "") {
      setOtherError("Please fill the field");
    } else if (regex.test(value)) {
      if (hospitalType === "Others") {
        setOther(value);
        setOtherError("");
      }
    } else {
      setOtherError("Invalid characters");
    }
  };

  const handleMobileNumberChange = (e) => {
    setMobile(e.target.value);
    const mobileNumberRegex = /^\d{10}$/;
    if (!e.target.value || !mobileNumberRegex.test(e.target.value)) {
      setIsMobileNumberError("Mobile Number is not valid");
    } else {
      setIsMobileNumberError("");
    }
  };

  const handleHospitalType = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (value === "" || regex.test(value)) {
      if (value !== "Others") {
        setOther("");
      }
      setHospitalType(value);
      setHospitalType(value);
      setHospitalTypeError("");
      setOtherError("");
    }
    if (value !== "" && !regex.test(value)) {
      setHospitalTypeError("Invalid Hospital type");
    }
    if (value === "") {
      setHospitalTypeError("Please fill the field");
    }
    if (value === "Others") {
      setOtherError("Fill the type here");
    }
  };

  useEffect(() => {
    if (profileData !== undefined) {
      if (!hospType.includes(profileData.hosptype)) {
        setHospitalType("Others");
        setOther(profileData.hosptype);
      } else {
        setHospitalType(profileData.hosptype);
      }
    }
  }, [profileData]);

  useEffect(() => {
    let data = {
      userid: userIds.userid,
      hospid: userIds.hospid,
    };

    dispatch(profile(data, token));
  }, [dispatch]);
  useEffect(() => {
    if (profileData !== undefined) {
      setName(profileData?.username);

      setState(profileData?.state);
      setZip(profileData?.zip);
      setCity(profileData?.city);

      setAddress(profileData?.address);
      setMobile(profileData?.mobile);
      setDesignation(profileData?.designate);
    }
  }, [profileData]);

  const handleEdit = (e) => {
    e.preventDefault();

    setSnackbarOpen(false);
    setSnackbarMessage("");
    if (
      nameError === "" &&
      isCityError === "" &&
      isStateError === "" &&
      isZipError === "" &&
      isAddressError === "" &&
      isMobileNumberError === "" &&
      designationError === "" &&
      otherError === ""
    ) {
      let data = {
        user_id: userIds.userid,
        hosp_type: hospitalType === "Others" ? other : hospitalType,
        hosp_address: address.trim(),
        hosp_city: city.trim(),
        hosp_state: state.trim(),
        hosp_zip: zip,
        designate: designation,
      };
      dispatch(profileEdit(data, token))
        .then(() => {
          setOpenModal(true);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSnackbarMessage("Please fill in all the required fields.");
      setSnackbarOpen(true);
    }
  };

  const hospType = [
    " Super Speciality",
    "Multi Speciality",
    "Clinic",
    "Private Hospital",
    "Government Hospital",
    "NGO",
    "Nursing Home",
  ];

  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));

  const handlePhotoUploadHistory = (e) => {
    const singlefile = e.target.files[0];

    if (singlefile) {
      const fileType = singlefile.type;
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const allowedSize = 2 * 1024 * 1024; // 2 MB

      if (!allowedTypes.includes(fileType)) {
        setIsPhotoErrorHistory(`Only JPEG or PNG files are allowed.`);
        return;
      }

      if (singlefile.size > allowedSize) {
        setIsPhotoErrorHistory("Files greater than 2 MB cannot be uploaded");
        return;
      }

      setIsPhotoErrorHistory(null);

      const reader = new FileReader();

      reader.onloadend = () => {
        let sendData = {
          hospid: userIds?.hospid,
          userid: userIds?.userid,
          branchid: userBranch?.id,
          imageid: `${uuid()}.${fileType.split("/")[1]}`,
          fileName: singlefile.name,
          fileType: fileType,
          image: reader.result, // base64 data of the image
        };

        // If there are files already uploaded, replace them, else add the new one
        if (fileUrlHistory.length > 0) {
          setFileUrlHistory([sendData]);
        } else {
          setFileUrlHistory([...fileUrlHistory, sendData]);
        }

        dispatch(addAccesoryUploadImageHistory(sendData, token));
      };

      reader.readAsDataURL(singlefile);
    }
    console.log(e);
  };

  const removePhotoHistory = (id) => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      imageid: id,
    };

    dispatch(deleteImageAddAccessoryHistory(data));
    setFileUrlHistory(fileUrlHistory.filter((item) => item.imageid !== id));
    console.log(id);
  };

  return (
    <MainDiv>
      <InnerContainer sx={{ bgcolor: "white", textAlign: "start" }}>
        <TextContainer>
          <Stack
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
              gap: "10px",
            }}
          >
            <Label variant="subtitle1">Hospital Details</Label>
            <Divider />
          </Stack>
          <FormWrapper>
            <FormElement
              sx={{
                padding: "0px",
              }}
            >
              <FormInput
                label="Hospital name"
                value={profileData?.hospname}
                variant="filled"
                sx={{ input: { color: "rgba(166, 166, 166, 1)" } }}
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
              {<HelperText>{}</HelperText>}
            </FormElement>

            <FormElement>
              <FormInput
                label="Hospital Type"
                variant="filled"
                select
                fullWidth
                value={hospitalType}
                onChange={handleHospitalType}
                c
                sx={{
                  textAlign: "start",

                  "& fieldset": { border: "none" },

                  bgcolor: "rgba(244, 246, 246, 1)",
                }}
              >
                {hospType.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
                <MenuItem value={"Others"}>Others</MenuItem>
              </FormInput>
              {<HelperText>{hospitalTypeError}</HelperText>}

              {hospitalType === "Others" && (
                <>
                  <FormInput
                    label="Hospital Type"
                    variant="filled"
                    InputProps={{ disableUnderline: true }}
                    value={other}
                    onChange={handleOthers}
                    placeholder={`Enter the type ${other}`}
                  />
                  {
                    <HelperText
                      sx={{
                        color:
                          otherError === "Fill the type here" &&
                          "rgba(70, 144, 255, 1)",
                      }}
                    >
                      {otherError}
                    </HelperText>
                  }
                </>
              )}
            </FormElement>

            <FormElement>
              <FormInput
                onChange={handleStateChange}
                label="State"
                value={state}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true }}
              />
              {<HelperText>{isStateError}</HelperText>}
            </FormElement>
            <FormElement>
              <FormInput
                onChange={handleCityChange}
                label="City"
                value={city}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true }}
              />
              {<HelperText>{isCityError}</HelperText>}
            </FormElement>
            <FormElement>
              <FormInput
                onChange={handlePinCodeChange}
                label="Zip"
                value={zip}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true }}
              />
              {<HelperText>{isZipError}</HelperText>}
            </FormElement>
            <FormElement>
              <FormInput
                onChange={handleAddressChange}
                label="Address"
                multiline
                maxRows={10}
                value={address}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true }}
              />
              {<HelperText>{isAddressError}</HelperText>}
            </FormElement>
          </FormWrapper>
          <Stack
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
              gap: "10px",
            }}
          >
            <Label variant="subtitle1">User Details</Label>
            <Divider />
          </Stack>
          <FormWrapper
            sx={{
              padding: "0px",
            }}
          >
            <FormElement>
              <FormInput
                onChange={handleNameChange}
                label="Name"
                sx={{ input: { color: "rgba(166, 166, 166, 1)" } }}
                value={name}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
              {<HelperText>{nameError}</HelperText>}
            </FormElement>
            <FormElement>
              <FormInput
                label="Email"
                value={profileData?.email}
                variant="filled"
                sx={{ input: { color: "rgba(166, 166, 166, 1)" } }}
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormElement>
            <FormElement>
              <FormInput
                onChange={handleDesignation}
                label="designation"
                value={designation}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true }}
              />
              {<HelperText>{designationError}</HelperText>}
            </FormElement>
            <FormElement>
              <FormInput
                onChange={handleMobileNumberChange}
                label="Contact No."
                value={mobile}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true }}
                sx={{ input: { color: "rgba(166, 166, 166, 1)" } }}
              />
              {<HelperText>{isMobileNumberError}</HelperText>}
            </FormElement>
          </FormWrapper>
          <Stack
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
              gap: "10px",
            }}
          >
            <Label variant="subtitle1">Subscription Details</Label>
            <Divider />
          </Stack>
          <FormWrapper
            sx={{
              padding: "0px",
            }}
          >
            <FormElement>
              <FormInput
                label="Subscription Plan "
                value={userIds.subs_plan}
                variant="filled"
                sx={{ input: { color: "rgba(166, 166, 166, 1)" } }}
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormElement>
            <FormElement>
              <FormInput
                label="Subscription Date "
                value={userIds.subs_date}
                variant="filled"
                sx={{ input: { color: "rgba(166, 166, 166, 1)" } }}
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormElement>
            <FormElement>
              <FormInput
                label="Subscription Expiry "
                value={userIds.subs_expdate}
                variant="filled"
                sx={{ input: { color: "rgba(166, 166, 166, 1)" } }}
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true, readOnly: true }}
              />
            </FormElement>
          </FormWrapper>
          <Divider sx={{ width: "100%", margin: "15px 0" }} />
          <Box sx={{ width: "100%" }}>
          
          </Box>
        </TextContainer>
      </InnerContainer>
      <UploadContainer sx={{}}>
        <>
          <BtnWrapper2 sx={{}}>
          
            {
              <Button
                size="large"
             
                onClick={handleEdit}
                sx={{
                  borderRadius: "35px",
                  textTransform: "none",
                  bgcolor: "rgba(70, 144, 255, 1)",
                }}
                variant="contained"
              >
                Submit
              </Button>
            }
          </BtnWrapper2>
        </>
      </UploadContainer>
      <SuccessPopUp
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        text={"Successful! "}
        subText1={"Changes have been successfully saved."}
        subText2={" "}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </MainDiv>
  );
}

export default Profile;
