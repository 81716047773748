import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Divider, Stack, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import DoneIcon from '@mui/icons-material/Done';
import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

const style = {
  bgcolor: "background.paper",
};
const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  height: "330px",
}));

export default function SuccessPopUp({
  openModal,
  handleCloseModal,
  response,
  text,

  subText1,
  subText2,
  redirectPath
}) {
  const navigate = useNavigate();

  

  const handleGotItClick = () => {
    handleCloseModal(); // Close the modal
    navigate(redirectPath); // Navigate to the specified path
  };
  return (
    <div style={{}}>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <ModalBox sx={style}>
          <Stack
            sx={{
              alignItems: "flex-end",
              justifyContent: "end",
              paddingRight: "10px",
            }}>
            <IconButton 
            onClick={handleCloseModal}
           
              >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",

              textAlign: "center",
              alignItems: "center",
            }}>
            <IconButton style={{ color: "rgba(0, 201, 32, 1)" }}>
              <DoneIcon style={{fontSize:'60px'}} />
            </IconButton>
            <Typography variant="h6" style={{ fontWeight: 700 }}>
              {text}
            </Typography>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {subText1} <br />
              {subText2}
            </Typography>
            <Stack sx={{ width: "90%" }}>
              <Divider style={{ background: "rgba(223, 223, 223, 1)" }} />
            </Stack>

            <Button
              size="small"
              variant="contained"
              onClick={handleCloseModal}
             
              sx={{
                borderRadius: "35px",

                textTransform: "none",
                bgcolor: "rgba(70, 144, 255, 1)",
              }}>
              Got it
            </Button>
          </Stack>
        </ModalBox>
      </Modal>
    </div>
  );
}
