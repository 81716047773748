import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Select,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import pmpdf from "../images/pmpdf.png";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadPDF,
  getPmAsset,
  postPmCheckList,
  postPmCheckListPdf,
} from "../redux/nbmData/action";
import { useNavigate, useParams } from "react-router-dom";
import SuccessModal from "../components/PmChecklistModal";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
const Main = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingTop: "20px",
  paddingBottom: "20px",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",
  [theme.breakpoints.down("xl")]: {
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  minHeight: "60px",

  gap: "10px",
  display: "grid",
  justifyItems: "center",
  gridTemplateColumns: "repeat(4,1fr)",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2,1fr)",
    minHeight: "120px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const SelectContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  minHeight: "75px",
  display: "flex",
  justifyItems: "center",

  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const InnerContainer = styled(Box)(({ theme }) => ({
  borderRadius: "30px",
  marginTop: "20px",
  paddingTop: "30px",
  paddingBottom: "30px",
  width: "90%",
  border: "1px solid rgba(33, 36, 39, 0.50)",
  boxShadow: "2px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",

  alignItems: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "99%",
    gap: "20px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormInput = styled(OutlinedInput)(({ theme }) => ({
  border: "none",
  width: "370px",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "360px",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    height: "50px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
    height: "50px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
    height: "50px",
  },
}));

const FormTextLabel = styled(FormLabel)(({ theme }) => ({
  textAlign: "left",

  color: "#1746A2",
  fontSize: "16px",
  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "90%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
    gap: "10px",
  },
  [theme.breakpoints.down("sm")]: {},
}));
const ParameterWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(1,1fr)",
  padding: "10px",
  // border: '1px solid blue',

  alignItems: "center",
  width: "90%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const ParaBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #1746A2",
  padding: "2px",
  marginBottom: 2,
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    justifyContent: "space-between",
    flexDirection: "column",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const RadioContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  // border: '1px solid green',
  width: "20%",
  // width: '40%',
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const ItemText = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  textTransform: "uppercase",
  fontSize: "18px",
  width: "80%",

  [theme.breakpoints.down("xl")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  color: "#1746A2",
  fontSize: "22px",
  marginTop: "10px",
  marginBottom: "10px",

  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("sm")]: {},
}));
const SecondSubTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  maxWidth: "90%",
  color: "#1746A2",
  fontSize: "18px",
  marginTop: "10px",
  marginBottom: "10px",

  [theme.breakpoints.down("xl")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down("sm")]: {},
}));
const SpecialDropDowne = styled(Stack)(({ theme }) => ({
  border: "none",
  width: "370px",
  height: "50px",
  [theme.breakpoints.down("xl")]: {
    width: "360px",
    height: "50px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
    height: "50px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
    height: "50px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
    height: "50px",
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  borderRadius: "35px",
  textTransform: "none",
  width: "150px",
  fontSize: "20px",
  height: "50px",

  [theme.breakpoints.down("xl")]: {
    width: "140px",
    height: "45px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "130px",
    height: "45px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

// const PdfButton = styled(Button)(({ theme }) => ({
//   borderRadius: "35px",
//   color: "#F78117",
//   border: "1px solid #FF731D",
//   textTransform: "none",
//   width: "192px",
//   height: "50px",

//   [theme.breakpoints.down("xl")]: {
//     width: "182px",
//     height: "50px",
//   },
//   [theme.breakpoints.down("lg")]: {},
//   [theme.breakpoints.down("md")]: {
//     width: "162px",
//     height: "45px",
//   },
//   [theme.breakpoints.down("sm")]: {},
//   [theme.breakpoints.down("xs")]: {},
// }));

const PdfButton = styled(Button)(({ theme }) => ({
  borderRadius: "35px",
  color: "#F78117",
  border: "1px solid #FF731D",
  textTransform: "none",
  // width: "192px",
  // height: "50px",

  [theme.breakpoints.down("xl")]: {
    // width: "182px",
    // height: "50px",
  },
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    // width: "162px",
    // height: "45px",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "50%",
  // flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",

  gap: "10px",
  marginTop: "20px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
    flexDirection: "column-reverse",
  },
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));
const FormInput2 = styled(DatePicker)(({ theme }) => ({
  border: "none",
  width: "370px",

  [theme.breakpoints.down("xl")]: {
    width: "360px",
  },
  [theme.breakpoints.down("lg")]: {
    width: "300px",
  },
  [theme.breakpoints.down("md")]: {
    width: "330px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "330px",
  },
}));

const TitleHead = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  textAlign: "left",

  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "22px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "22px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SecondTitle = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  textAlign: "center",

  fonteight: "400",
  color: "#FF731D",
  [theme.breakpoints.down("xl")]: {
    fonteight: "400",
    fontSize: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    fonteight: "400",
    fontSize: "20px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("md")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("sm")]: {
    fonteight: "500",
    fontSize: "18px",
    lineHeight: "33px",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const ContentText = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  width: "100%",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bgcolor: "white",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const TopContainers = styled(Box)(({ theme }) => ({
  width: "90%",

  margin: "auto",
  marginTop: "2%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {
    border: "none",
  },
  [theme.breakpoints.down("md")]: {
    border: "none",
  },
  [theme.breakpoints.down("sm")]: {
    border: "none",
  },
  [theme.breakpoints.down("xs")]: {},
}));

const SecondContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "2%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
  [theme.breakpoints.down("xs")]: {},
}));

const FrameBox = styled(Box)(({ theme }) => ({
  width: "80%",
  // height: "600px",

  paddingBottom: "30px",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    width: "90%",
    // height: "500px",
  },
  [theme.breakpoints.down("sm")]: {},
}));

function PmCheckList() {
  let userIds = JSON.parse(sessionStorage.getItem("userData")) ||
  JSON.parse(localStorage.getItem("userData"))
  const [flag, setFlag] = useState(false);
  const token = localStorage.getItem("userToken");
  const pmChecklistData = useSelector(
    (store) => store.data.postPmCheckListData
  );
  let userBranch =
    JSON.parse(sessionStorage.getItem("userBranch")) ||
    JSON.parse(localStorage.getItem("userBranch"));
  const pdfUrl = useSelector((store) => store.data.pmChecklistPdf);
  const params = useParams();
  const [equipment, setEquipment] = useState("");
  const [open, setOpen] = useState(false);
  const [department, setDepartment] = useState([]);
  const [iframeData, setIframeData] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [singleDepartment, setSingleDepartment] = useState("");
  const [save, setSave] = useState(false);
  const [check, setCheck] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [assetCode, setAssetCode] = useState("");
  const [assetName, setAssetName] = useState("");
  const [remark, setRemark] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [doneBy, setDoneBy] = useState("");
  const [isParameterError, setIsParameterError] = useState("");
  const [isDoneByError, setIsDoneByError] = useState("");
  const [isDateError, setIsDateError] = useState("");
  const [isAssetCodeError, setIsAssetCodeError] = useState("");
  const [isSerialNumberError, setIsSerialNumberError] = useState("");
  const [isModelNumberError, setIsModelNumberError] = useState("");
  const [isBrandError, setIsBrandError] = useState("");
  const [isRemarkError, setIsRemarkError] = useState("");
  const [isAssetNameError, setIsAssetNameError] = useState("");
  const [dropdown, setDropdown] = useState("");
  let userAccess =
    JSON.parse(sessionStorage.getItem("user_access")) ||
    JSON.parse(localStorage.getItem("user_access"));
  const dispatch = useDispatch();
  const [color, setColor] = useState("");
  const [assetId, setAssetId] = useState("");
  const [getAllData, setGetAllData] = useState([]);
  const assetData = useSelector((store) => store.data.getPmAssetData);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [url, setUrl] = useState(null);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(0),
      right: theme.spacing(0),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2.5),
      left: theme.spacing(1),
    },
  }));

  let data2 = [
    {
      department: "Critical Care",
      equipment: [
        {
          name: "NST MACHINE",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "PAPPER NOTCH", status: "N/A" },
            { name: "TRANSDUCERS", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "BABY WEIGHING SCALE",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "WEIGHT TEST", status: "N/A" },
            { name: "0.5kg", status: "N/A" },
            { name: "1kg", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "SPO2 MONITOR",
          parameter: [
            { name: "BATTERY COVER", status: "N/A" },
            { name: "LED", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "BABY RESUSCITATOR",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            {
              name: "Temperature sensor and sensor cover for prolonged resuscitation",
              status: "N/A",
            },
            { name: "Plastic bag or plastic wrap", status: "N/A" },
            { name: "Cleanliness", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "ABG ANALYSER",
          parameter: [
            { name: "CHARGER/MAIN", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "CATRIDGE AREA", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "THERMAL PAPER", status: "N/A" },
            { name: "MEMORY", status: "N/A" },
          ],
        },
        {
          name: "DEFIBRILLATOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "KEY PAD", status: "N/A" },
            { name: "PADDLES", status: "N/A" },
            { name: "CLEANED", status: "N/A" },
            { name: "SPRINTER", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "NOISE LEVEL", status: "N/A" },
            { name: "PATTERN TEST", status: "N/A" },
            { name: "ALARM", status: "N/A" },
            { name: "VERIFICATION", status: "N/A" },
            { name: "HEART RATE TEST : 80", status: "N/A" },
            { name: "SET ENERGY", status: "N/A" },
            { name: "50J", status: "N/A" },
            { name: "70J", status: "N/A" },
            { name: "100J", status: "N/A" },
            { name: "150J", status: "N/A" },
            { name: "200J", status: "N/A" },
          ],
        },
        {
          name: "ECG MACHINE",
          parameter: [
            {
              name: "AC MAINS VOLTAGE /N to E=3v/N to L=230v/L to E=230v",
              status: "N/A",
            },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "CLEANESS OF MACHINE", status: "N/A" },
            { name: "PRINTER CONDITION", status: "N/A" },
            { name: "BATTERY TEST", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "NOISE LEVEL", status: "N/A" },
            { name: "CALIBRATION", status: "N/A" },
            { name: "VALUE SELECTED 30", status: "N/A" },
            { name: "VALUE SELECTED 60", status: "N/A" },
            { name: "VALUE SELECTED 120", status: "N/A" },
          ],
        },
        {
          name: "BP APPARATUS ",
          parameter: [
            {
              name: "CHECK THE CLEANLINESS,COMPLETENESS,DAMAGE OF THE UNIT",
              status: "N/A",
            },
            { name: "CHECK THE BULB WITH CONTROL VALVE", status: "N/A" },
            { name: "CHECK THE BP CUFF", status: "N/A" },
            { name: "CLEAN THE MERCURY", status: "N/A" },
            { name: "REPLACE THE RUBBER WASHER IF REQUIRED", status: "N/A" },
          ],
        },
        {
          name: "INCUBATOR",
          parameter: [
            { name: "Power indicator", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Equipment display", status: "N/A" },
            { name: "Keypad", status: "N/A" },
            { name: "Heater function", status: "N/A" },
            { name: "Temperature display", status: "N/A" },
            { name: "Door locking movement", status: "N/A" },
            { name: "Sensor function", status: "N/A" },
            { name: "Feather touch switches", status: "N/A" },
          ],
        },
        {
          name: "LIGHT SOURCE",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch Function", status: "N/A" },
            { name: "Bulb Holders", status: "N/A" },
            { name: "Power ON Indicator", status: "N/A" },
            { name: "Fiber Optic Cable", status: "N/A" },
            { name: "Fan Function", status: "N/A" },
            { name: "Adapter for Light Cable", status: "N/A" },
          ],
        },
        {
          name: "MDICAL MONITOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "TRIM KNOB", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "REMOTE FUNCTION", status: "N/A" },
          ],
        },
        {
          name: "MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "FOUCS", status: "N/A" },
            { name: "STAGE AND CLIPS", status: "N/A" },
            { name: "RACK STOP", status: "N/A" },
            { name: "ARM", status: "N/A" },
            { name: "BULB", status: "N/A" },
          ],
        },
        {
          name: "PATIENT MONITOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "TRIM KNOB", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "ECG CABLE", status: "N/A" },
            { name: "NIBP CUFF", status: "N/A" },
            { name: "SPO2 PROBE", status: "N/A" },
          ],
        },

        {
          name: "TOURNIQUET",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Pressure bag and hose function", status: "N/A" },
            { name: "Timer Function", status: "N/A" },
            { name: "Pump Function", status: "N/A" },
            { name: "All Switches", status: "N/A" },
            { name: "Test Point Voltages", status: "N/A" },
          ],
        },

        {
          name: "VENTILATOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "BUTTONS", status: "N/A" },
            { name: "CIRCUIT ASSEMBLY", status: "N/A" },
            { name: "SENSORS", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "O2 AND AIR HOSE", status: "N/A" },
            { name: "CLENINESS", status: "N/A" },
          ],
        },
      ],
    },

    {
      department: "OT",
      equipment: [
        {
          name: "Cautery Machine or Diathermy",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "FOOT SWITCH", status: "N/A" },
            { name: "BIPLOAR& MONOPOLAR CABLE WORKING", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "SPO2 MONITOR",
          parameter: [
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "LED", status: "N/A" },
            { name: "BATTERY COVER", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "CAUTERY MACHINE",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "FOOT SWITCH", status: "N/A" },
            { name: "BIPLOAR& MONOPOLAR CABLE WORKING", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "BABY RESUSCITATOR",
          parameter: [
            { name: "KEYPAD ", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            {
              name: "Temperature sensor and sensor cover for prolonged resuscitation",
              status: "N/A",
            },
            { name: "Plastic bag or plastic wrap", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "Check for proper working of alarms", status: "N/A" },
            { name: "Cleanliness", status: "N/A" },
          ],
        },
        {
          name: "ANAESTHESIA MACHINE",
          parameter: [
            { name: "AC MAINS/POWEP CORD/BATTERY BACK UP", status: "N/A" },
            {
              name: "DISASSEMBLY OF BREATHING SYSTEMS AND BELLOWS ASSEMS ASSEMBLY FOR CLEANING",
              status: "N/A",
            },
            {
              name: "CLEANING OF FILTER /FLOW SENSORS FIT NEW SEALS BODOKS,RING",
              status: "N/A",
            },
            { name: "LEAKAGE  TEST WITHOUT VAPORIZER", status: "N/A" },
            { name: "LEAKAGE TEST WITH VAPORIZER", status: "N/A" },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY CYLINDER",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY PIPE LINE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF OXIGEN FAILURE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF CIRCLE ABSORBER",
              status: "N/A",
            },
            { name: "CHECK FOR PROPER MOVEMENTS OF WHEELS", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
            { name: "CALIBRATION OF OXIGEN SENSORS", status: "N/A" },
            {
              name: "FINAL TEST OF TIDAL VOLUME RATE AND I:E RATIO ,FIO2",
              status: "N/A",
            },
          ],
        },
        {
          name: "BP APPARATUS",
          parameter: [
            {
              name: "CHECK THE CLEANLINESS,COMPLETENESS,DAMAGE OF THE UNIT",
              status: "N/A",
            },
            { name: "CHECK THE BULB WITH CONTROL VALVE", status: "N/A" },
            { name: "CHECK THE BP CUFF", status: "N/A" },
            { name: "CLEAN THE MERCURY", status: "N/A" },
            { name: "REPLACE THE RUBBER WASHER IF REQUIRED", status: "N/A" },
          ],
        },
        {
          name: "DEFIBRILLATOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "KEY PAD", status: "N/A" },
            { name: "PADDLES", status: "N/A" },
            { name: "CLEANED", status: "N/A" },
            { name: "SPRINTER", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "SELF TEST", status: "N/A" },
            { name: "NOISE LEVEL", status: "N/A" },
            { name: "PATTERN TEST", status: "N/A" },
            { name: "ALARM", status: "N/A" },
            { name: "VERIFICATION", status: "N/A" },
            { name: "HEART RATE TEST : 80", status: "N/A" },
            { name: "SET ENERGY ", status: "N/A" },
          ],
        },
        {
          name: "ELECTRO SURGICAL UNIT",
          parameter: [
            { name: "KEY PAD FUNCTIONING", status: "N/A" },
            { name: "FOOTSWITH FUNCTIONING IN ALL MODES", status: "N/A" },
            { name: "MONOPOLAR  ND BIPOLAR FUNCTIONING", status: "N/A" },
            { name: "REM CONNECTION CHECK WITH ELECTRODE", status: "N/A" },
            { name: "ALARMS CHECK AND CLEANEESS", status: "N/A" },
            { name: "THE POWER SUPPLY", status: "N/A" },
          ],
          powerSupply: [
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+5V(RANGE0.15)AT  TP8",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE+/- 0.36)AT TP6",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE +/-0.36)AT TP5",
              observedValue: "",
            },
          ],
        },
        {
          name: "ETO STERLIZER",
          parameter: [
            { name: "DISPLAY AND KEYPAD", status: "N/A" },
            { name: "POWER INTEGRITY", status: "N/A" },
            { name: "FUSES", status: "N/A" },
            { name: "INPUT AIR REGULATOR", status: "N/A" },
            { name: "EXHAUST SYSTEM", status: "N/A" },
            { name: "DOOR LOCKING MECHANISM", status: "N/A" },
            { name: "GASKET", status: "N/A" },
            { name: "THERMAL SWITCH", status: "N/A" },
            { name: "LEAK TESTS", status: "N/A" },
            {
              name: "ETHYLENE OXIDE CARTRIDGE MOUNTING MACHINE",
              status: "N/A",
            },
          ],
        },
        {
          name: "FLASH AUTOCLAVE",
          parameter: [
            { name: "DISPLAY AND KEYPAD", status: "N/A" },
            { name: "GASKET CLEANING", status: "N/A" },
            { name: "DOOR LOCKING", status: "N/A" },
            { name: "TEMPERATURE INDICATION", status: "N/A" },
            { name: "WATR LEVEL INDICATION", status: "N/A" },
            { name: "DOOR LOCKING INDICAION", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
          ],
        },
        {
          name: "ANAESTHESIA MACHINE",
          parameter: [
            { name: "AC MAINS/POWEP CORD/BATTERY BACK UP", status: "N/A" },
            {
              name: "DISASSEMBLY OF BREATHING SYSTEMS AND BELLOWS ASSEMS ASSEMBLY FOR CLEANING",
              status: "N/A",
            },
            {
              name: "CLEANING OF FILTER /FLOW SENSORS FIT NEW SEALS BODOKS,RING",
              status: "N/A",
            },
            { name: "LEAKAGE  TEST WITHOUT VAPORIZER", status: "N/A" },
            { name: "LEAKAGE TEST WITH VAPORIZER", status: "N/A" },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY CYLINDER",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF MACHINE WITH ONLY PIPE LINE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF OXIGEN FAILURE",
              status: "N/A",
            },
            {
              name: "CHECK FOR PROPER WORKING OF CIRCLE ABSORBER",
              status: "N/A",
            },
            { name: "CHECK FOR PROPER MOVEMENTS OF WHEELS", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
            { name: "CALIBRATION OF OXIGEN SENSORS", status: "N/A" },
            {
              name: "FINAL TEST OF TIDAL VOLUME RATE AND I:E RATIO ,FIO2",
              status: "N/A",
            },
          ],
        },
        {
          name: "HARMONIC SCALPEL",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Accessories Integrity Check", status: "N/A" },
            { name: "All Switches Function", status: "N/A" },
            { name: "High Frequency Module Checking", status: "N/A" },
            { name: "Probe Integrity", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Alarm Function", status: "N/A" },
          ],
          powerSupply: [
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+5V(RANGE0.15)AT  TP8",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE+/- 0.36)AT TP6",
              observedValue: "",
            },
            {
              supplyParameter: "Low Voltage",
              selectedValue: "+12V(RANGE +/-0.36)AT TP5",
              observedValue: "",
            },
          ],
        },
        {
          name: "LIGHT SOURCE",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch Function", status: "N/A" },
            { name: "Bulb Holders", status: "N/A" },
            { name: "Power ON Indicator", status: "N/A" },
            { name: "Fan Function", status: "N/A" },
            { name: "Adapter for Light Cable", status: "N/A" },
          ],
        },
        {
          name: "LITHOCLAST",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display Functions", status: "N/A" },
            { name: "Accessories Integrity Check", status: "N/A" },
            { name: "Probe Integrity", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Alarm Function", status: "N/A" },
          ],
        },
        {
          name: "MDICAL MONITOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "TRIM KNOB", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "REMOTE FUNCTION", status: "N/A" },
          ],
        },
        {
          name: "MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "FOUCS", status: "N/A" },
            { name: "STAGE AND CLIPS", status: "N/A" },
            { name: "RACK STOP", status: "N/A" },
            { name: "ARM", status: "N/A" },
            { name: "BULB", status: "N/A" },
          ],
        },
        {
          name: "ORTHO DRILL",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Motor Function", status: "N/A" },
            { name: "Hand Unit Function", status: "N/A" },
            { name: "Forward/Reverse Function", status: "N/A" },
            { name: "Speed Variation Function", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Indicators", status: "N/A" },
          ],
        },
        {
          name: "OT  LIGHT",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch Function", status: "N/A" },
            { name: "Bulb Holders", status: "N/A" },
            { name: "Optical Assembly", status: "N/A" },
            { name: "Different Positioning of Domes", status: "N/A" },
            { name: "Dome  Intensity", status: "N/A" },
            { name: "Battery Backup Function", status: "N/A" },
            { name: "Light Beam Focusing", status: "N/A" },
          ],
        },
        {
          name: "OT TABLE",
          parameter: [
            { name: "PHYSICAL APPERANCE OF THE TABLE", status: "N/A" },
            { name: "NO HYDRAULIC FLUID LEAKAGE", status: "N/A" },
            { name: "MOVEMENT OF TABLE ON CASTORS", status: "N/A" },
            { name: "FIXATION OF TABLE TO THE GROUND", status: "N/A" },
            { name: "CONNECTIVITY OF TABLE WITH MAINS CORD", status: "N/A" },
            { name: "LED INDICATION ON REMOTE/ CONTROL PANEL", status: "N/A" },
            {
              name: "OPERATION OF THE  TABLE  BY REMOTE CONTROL PANEL",
              status: "N/A",
            },
            {
              name: "OPERATION OF TABLE  BY OVERRIDE /EMERGENCY CONTROL PANEL",
              status: "N/A",
            },
            { name: "OPERATION OF TABLE  ON BACK UP BATTERIES", status: "N/A" },
            {
              name: "LOCKING SYSTEM AND FIXATION OF VARIOUS  PARTS (HEAD REST,LEG SUPPORTS EXTENSIONS ETC.)",
              status: "N/A",
            },
            { name: "FIXATION OF ACCESSORIES TO THE TABLE ", status: "N/A" },
            { name: "CORRECT POSITIONING OF CUSHIONS", status: "N/A" },
            { name: "BATTERY VOLTAGE  24+/-2 V DC", status: "N/A" },
          ],
        },
        {
          name: "STEEM STERILIZER",
          parameter: [
            { name: "POWER  SUPPLY", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "SAFETY  VALVE,TEMPERATURE GUAGE", status: "N/A" },
            { name: "PRESSURE  GUAGE", status: "N/A" },
            { name: "HEATING COIL", status: "N/A" },
            { name: "LEAKAGE", status: "N/A" },
            { name: "OVER ALL CLEANING", status: "N/A" },
          ],
        },
        {
          name: "SUCTION MACHINE",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Battery Voltage", status: "N/A" },
            { name: "Tubings", status: "N/A" },
            { name: "Suction Container", status: "N/A" },
            { name: "Pressure", status: "N/A" },
            { name: "Earth Resistance", status: "N/A" },
            { name: "Leakage Current", status: "N/A" },
          ],
        },
        {
          name: "SYRINGE PMP",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEY PAD&DISPLAY", status: "N/A" },
            { name: "ALARM FUNCTION", status: "N/A" },
            { name: "OCCULSION", status: "N/A" },
            { name: "NEARLY EMPTY", status: "N/A" },
            { name: "BATTERY", status: "N/A" },
            { name: "SYRINGE DETECTION", status: "N/A" },
            { name: "10ML,20ML", status: "N/A" },
            { name: "50ML", status: "N/A" },
          ],
        },
        {
          name: "TOURNIQUET",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Pressure bag and hose function", status: "N/A" },
            { name: "Timer Function", status: "N/A" },
            { name: "Pump Function", status: "N/A" },
            { name: "All Switches", status: "N/A" },
            { name: "Test Point Voltages", status: "N/A" },
          ],
        },
      ],
    },
    {
      department: "Laboratory",
      equipment: [
        {
          name: "BIOCHEMISTRY ANALYSER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "SUCKING TUBE", status: "N/A" },
            { name: "MOVING ART", status: "N/A" },
            { name: "ELECTRICAL SAFEY", status: "N/A" },
            { name: "TUBINGS", status: "N/A" },
            { name: "INTERNAL CLEANING ", status: "N/A" },
          ],
        },
        {
          name: "BIOSAFETY CABINET",
          parameter: [
            { name: "Proper leveling and Stability", status: "N/A" },
            {
              name: "Proper flicker free operation of Florescent light",
              status: "N/A",
            },
            {
              name: "Proper operation of work chamber UV light",
              status: "N/A",
            },
            {
              name: "Proper operation of exhaust UV light through the check hole in the chamber,located above the gas cock",
              status: "N/A",
            },
            { name: "Manometer float lifting", status: "N/A" },
            {
              name: "Operation of all electrical switches on the control panel",
              status: "N/A",
            },
            {
              name: "Checked the gas leakage from the gas cock with soap solution",
              status: "N/A",
            },
            { name: "Checked the smooth door operation", status: "N/A" },
          ],
        },
        {
          name: "BLOOD CULTURE SYSTEM",
          parameter: [
            { name: "Power switch", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Key pad nad LCD display", status: "N/A" },
            { name: "Home Rotor Key/Rotor", status: "N/A" },
            { name: "Alaram Indicator", status: "N/A" },
            { name: "Barcode reader function ", status: "N/A" },
            { name: "Temperature verification", status: "N/A" },
            { name: "New positive indicator", status: "N/A" },
            { name: "Door interlock switch", status: "N/A" },
            { name: "Floppy Disk Drive", status: "N/A" },
            { name: "System start UP", status: "N/A" },
            { name: "Air Filter cleaning", status: "N/A" },
          ],
        },
        {
          name: "BLOOD MIXER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "ROTATING ARM", status: "N/A" },
            { name: "ROTATING SPEED", status: "N/A" },
            { name: "CUSION ON ROTATING ARM", status: "N/A" },
            { name: "TRAY", status: "N/A" },
            { name: "EARTHING", status: "N/A" },
          ],
        },
        {
          name: "CENTRIFUGE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "TUBES", status: "N/A" },
            { name: "CARBON BRUSHES", status: "N/A" },
            { name: "CLEANINESS", status: "N/A" },
            { name: "LUBRICANT", status: "N/A" },
            { name: "ELECTRICAL SAFETY", status: "N/A" },
          ],
        },
        {
          name: "COAGULOMETER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECTRICAL SAFETY", status: "N/A" },
            { name: "CHNNEL", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "PAPER", status: "N/A" },
            { name: "LAMP", status: "N/A" },
          ],
        },
        {
          name: "COBLATOR",
          parameter: [
            { name: "Power Integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Display/Keyboard Function", status: "N/A" },
            { name: "Visual Inspection", status: "N/A" },
            { name: "Accessories Integrity Check", status: "N/A" },
            { name: "Probe Integrity", status: "N/A" },
            { name: "All Switches Function", status: "N/A" },
            { name: "Foot Control Function", status: "N/A" },
            { name: "Alarm Function", status: "N/A" },
            { name: "Test Point Voltages", status: "N/A" },
          ],
        },
        {
          name: "ELOCTOLYTE ANLYSER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "SCREEN", status: "N/A" },
            { name: "BUTTONS", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "SAMPLE SUCKING", status: "N/A" },
            { name: "TUBINGS", status: "N/A" },
            { name: "INTERNAL CLEANING", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "OVER ALL CLEANINESS", status: "N/A" },
          ],
        },
        {
          name: "ELISA READER",
          parameter: [
            { name: "Power integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Equipment filters", status: "N/A" },
            { name: "Equipment lamp", status: "N/A" },
            { name: "Measuring tray movement", status: "N/A" },
            { name: "Dispensing and aspiration", status: "N/A" },
            { name: "Switch function", status: "N/A" },
            { name: "Equipment display", status: "N/A" },
            { name: "Tubing", status: "N/A" },
            { name: "Earth resistance", status: "N/A" },
          ],
        },
        {
          name: "ENT MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "Lamps", status: "N/A" },
            { name: "Connectors", status: "N/A" },
            { name: "Objectives", status: "N/A" },
            { name: "Condenser", status: "N/A" },
            { name: "Mechanical Movements", status: "N/A" },
          ],
        },
        {
          name: "HEMOSTASIS  ANALYZER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "TOUCH SCREEN", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "PRINTER", status: "N/A" },
            { name: "SAMPLE  SUKING", status: "N/A" },
            { name: "TUBINGS", status: "N/A" },
            { name: "INTERNAL CLEANING", status: "N/A" },
            { name: "REAGENTS", status: "N/A" },
            { name: "OVER ALL CLEANINESS", status: "N/A" },
          ],
        },
        {
          name: "MICROSCOPE",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "FUSE", status: "N/A" },
            { name: "EYE PIECE LENS", status: "N/A" },
            { name: "FOUCS", status: "N/A" },
            { name: "STAGE AND CLIPS", status: "N/A" },
            { name: "RACK STOP", status: "N/A" },
            { name: "ARM", status: "N/A" },
            { name: "BULB", status: "N/A" },
          ],
        },
        {
          name: "SEMI AUTO MATIC ANALYSER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "SUCKING TUBE", status: "N/A" },
            { name: "SAMPLE AREA", status: "N/A" },
            { name: "ELECTRICAL SAFEY", status: "N/A" },
            { name: "TOUCH SCREEN", status: "N/A" },
            { name: "INTERNAL CLEANING ", status: "N/A" },
            { name: "LAMP", status: "N/A" },
            { name: "TUBINS", status: "N/A" },
          ],
        },
        {
          name: "VDRL SHAKER",
          parameter: [
            { name: "Power integrity", status: "N/A" },
            { name: "Fuses", status: "N/A" },
            { name: "Switch functions", status: "N/A" },
            { name: "Mechanical movements", status: "N/A" },
            { name: "Leeds", status: "N/A" },
            { name: "Rollers", status: "N/A" },
            { name: "Earth resistance", status: "N/A" },
            { name: "Leakage current", status: "N/A" },
          ],
        },
      ],
    },
    {
      department: "Radiology",
      equipment: [
        {
          name: "ECHO MACHINE(HAND HELD)",
          parameter: [
            { name: "AC MAINS/POWER CORD", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "ECHO PROBE", status: "N/A" },
            { name: "BATTERY BACK UP", status: "N/A" },
            { name: "CHECK FOR PROPER WORKING OF ALARMS", status: "N/A" },
          ],
        },
        {
          name: "CR PRINTER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD  FUNCTION", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "PHYSICAL APPERANCE", status: "N/A" },
            { name: "CONTRAST", status: "N/A" },
            { name: "PRINT QUALITY", status: "N/A" },
            { name: "SELF TEST SEQUENCE", status: "N/A" },
          ],
        },
        {
          name: "CR READER",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD  FUNCTION", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "PHYSICAL APPERANCE", status: "N/A" },
            { name: "LOADING SLOT", status: "N/A" },
            { name: "SCANNING TIME", status: "N/A" },
            { name: "EJECTION", status: "N/A" },
            { name: "CLEANESS", status: "N/A" },
          ],
        },
        {
          name: "PRESSURE INJECTOR",
          parameter: [
            { name: "AC MAINS/POWEP CORD", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "KEYPAD  FUNCTION", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
            { name: "CONTROLS & SETTINGS", status: "N/A" },
            { name: "WALL MOUNTED STAND", status: "N/A" },
            { name: "MECHANICAL ASSEMBLY CHECKING", status: "N/A" },
            { name: "PARAMETERS & SAMPLE  READINGS", status: "N/A" },
            { name: "CHECK FOR ALL SCREWS, KNOBS", status: "N/A" },
          ],
        },
        {
          name: "STEEM STERILIZER",
          parameter: [
            { name: "POWER  SUPPLY", status: "N/A" },
            { name: "ELECRTICAL SAFETY", status: "N/A" },
            { name: "SAFETY  VALVE,TEMPERATURE GUAGE", status: "N/A" },
            { name: "PRESSURE  GUAGE", status: "N/A" },
            { name: "HEATING COIL", status: "N/A" },
            { name: "LEAKAGE", status: "N/A" },
            { name: "OVER ALL CLEANING", status: "N/A" },
          ],
        },
        {
          name: "ULTRASOUND",
          parameter: [
            { name: "SURFACE CLEANING/INTERNAL CLEANING", status: "N/A" },
            { name: "CHECKED ELECTRICAL CONNECTION AND CABLE", status: "N/A" },
            { name: "IMAGE QUALITY", status: "N/A" },
            { name: "TRANSDUCER CONNECTION", status: "N/A" },
            { name: "KEYPAD", status: "N/A" },
            { name: "DISPLAY", status: "N/A" },
          ],
        },
      ],
    },
  ];

  const handleEquipment = (e, value) => {
    setDropdown(value);
    setBrand("");
    setAssetCode("");
    setAssetName("");
    setDoneBy("");
    setRemark("");
    setStartDate("");
    setEndDate("");
    setSingleDepartment("");
    setSerialNumber("");
    setModelNumber("");
    setCheck([]);

    let selectedEquipment = department?.equipment?.filter((name) => {
      return name?.name === value;
    });

    setCheck([...selectedEquipment]);
    setFlag(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleChangeStatus = (index, value) => {
    setSelectedOption(value);
    const updatedParameters = [...check];
    updatedParameters[0].parameter[index].status = value;
    setCheck(updatedParameters);
    setIsParameterError("");
    setFlag(false);
  };

  const handleChangeObservedValue = (e, index) => {
    const value = e.target.value;
    const updatedObservedValue = [...check];
    updatedObservedValue[0].powerSupply[index].observedValue = value;
    setCheck(updatedObservedValue);
    setIsParameterError("");
    setFlag(false);
  };

  const handleSerialNumber = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9-]+$/;
    if (regex.test(value) || value === "") {
      setSerialNumber(value);
      setIsSerialNumberError("");
      setFlag(false);
    }
    if (value !== "" && !regex.test(value)) {
      setIsSerialNumberError("wrong serial number");
    }
  };

  const handleModelNumber = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z0-9-]+$/;
    if (regex.test(value) || value === "") {
      setModelNumber(value);
      setIsModelNumberError("");
      setFlag(false);
    }
    if (value !== "" && !regex.test(value)) {
      setIsModelNumberError("wrong model number");
    }
  };

  const handleAssetCode = (e) => {
    const value = e.target.value;
    setAssetCode(value);
    setFlag(false);
  };

  const handleSingleDepartment = (e) => {
    const value = e.target.value;
    setSingleDepartment(value);
    setFlag(false);
  };

  const handleAssetName = (e, newValue) => {
    if (newValue !== null && newValue !== undefined) {
      setAssetName(newValue.label);
      filterDetails(newValue.value);
      setIsAssetNameError("");
    } else {
      setIsAssetNameError("Please fill the field");
      setBrand("");
      setAssetCode("");
      setSingleDepartment("");
      setSerialNumber("");
      setModelNumber("");
    }
  };

  const hanldeStartDate = (date) => {
    setStartDate(date);
    handleDate(date, endDate);
    setFlag(false);
  };
  const hanldeEndDate = (date) => {
    setEndDate(date);
    handleDate(startDate, date);
    setFlag(false);
  };

  const handleDate = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setIsDateError("Invalid date Range");
    } else {
      setIsDateError("");
    }
  };

  const handleRemark = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;

    if (value === "" || regex.test(value)) {
      setRemark(value);
      setIsRemarkError("");
      setFlag(false);
    }
    if (value !== "" && !regex.test(value)) {
      setIsRemarkError("Invalid character");
    }
    if (value === "") {
      setIsRemarkError("Please fill the field");
    }
  };

  const handleDoneBy = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;

    if (value === "" || regex.test(value)) {
      setDoneBy(value);
      setFlag(false);
      setIsDoneByError("");
    }
    if (value !== "" && !regex.test(value)) {
      setIsDoneByError("wrong Name");
    }
    if (value === "") {
      setIsDoneByError("Please fill the field");
    }
  };

  const handleBrand = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (value === "" || regex.test(value)) {
      setBrand(value);
      setIsBrandError("");
      setFlag(false);
    }
    if (value !== "" && !regex.test(value)) {
      setIsBrandError("wrong brand Name");
    }
    if (value === "") {
      setIsBrandError("Please fill the field");
    }
  };

  const filterDetails = (asset) => {
    let filterData = getAllData?.filter((item) => {
      return item.assetid === asset;
    });
    let singleData = filterData[0];
    setSerialNumber(singleData?.serialno);
    setModelNumber(singleData?.modelno);
    setBrand(singleData?.brand);
    setAssetCode(singleData?.assetcode);
    setSingleDepartment(singleData?.department);
    setAssetId(singleData?.assetid);
    setDepartmentId(singleData.department_id);
  };

  const handleChoose = (e) => {
    setColor(e.target.value);
    setDropdown("");
    setCheck([]);
    let value = e.target.value;

    let filterData = data2?.filter((item) => {
      setDepartment([]);
      return item?.department === value;
    });
    setDepartment(...filterData);
  };

  useEffect(() => {
    let data = {
      hospid: userIds.hospid,
      userid: userIds.userid,
      branchid: userBranch?.id,
      depart_ids: userAccess?.departmentids,
    };

    dispatch(getPmAsset(data, token));
  }, [dispatch]);

  useEffect(() => {
    setColor(params);

    let value = params.id;

    let filterData = data2?.filter((item) => {
      return item.department === value;
    });

    setDepartment(...filterData);
  }, []);

  useEffect(() => {
    if (assetData && assetData?.length !== 0) {
      setGetAllData(assetData);
    }
  }, [assetData, equipment]);

  const hasEmptyStatus = check[0]?.parameter.some((item) => {
    return item.status !== "N/A";
  });
  const formattedStartDate = dayjs(startDate).format("DD-MM-YYYY");
  const formattedEndDate = dayjs(endDate).format("DD-MM-YYYY");

  const handleSubmit = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
    (endDate === "" || startDate === "") &&
      setIsDateError("Please fill the field");
    doneBy === "" && setIsDoneByError("Please fill the field");
    remark === "" && setIsRemarkError("Please fill the field");
    doneBy === "" && setIsDoneByError("Please fill the field");
    assetName === "" && setIsAssetNameError("Please fill the field");
    hasEmptyStatus === false &&
      setIsParameterError("Please fill the parameter");

    if (
      isAssetNameError === "" &&
      isDateError === "" &&
      isDoneByError === "" &&
      isModelNumberError === "" &&
      isRemarkError === "" &&
      isSerialNumberError === ""
    ) {
      if (
        assetName !== "" &&
        serialNumber !== "" &&
        modelNumber !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        remark !== "" &&
        doneBy !== "" &&
        hasEmptyStatus === true
      ) {
        let data = {
          userid: userIds?.userid,
          username: userIds?.username,
          hospid: userIds?.hospid,
          hosp_name: userIds?.hospname,
          asset_name: assetName,
          asset_id: assetId,
          asset_code: assetCode,
          brand: brand,
          serial_no: serialNumber,
          model_no: modelNumber,
          department: singleDepartment,
          pm_done_date: formattedStartDate,
          pm_due_date: formattedEndDate,
          remarks: remark,
          done_by: doneBy,
          parameter: check[0]?.parameter,
          branchid: userBranch?.id,
          power_supply:
            check[0]?.powerSupply === undefined ? [] : check[0]?.powerSupply,
        };

        setSave(true);
        setIsParameterError("");
        setIframeData(data);
        generatePDF(data);

        startTimer();
        setFlag(true);
        // dispatch(postPmCheckList(data, token));

        // downloadPDF(data)
      } else {
        setSnackbarMessage("Please fill in all the required fields.");
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage("Please fill in all the required fields.");
      setSnackbarOpen(true);
    }
  };

  const generatePDF = (asset) => {
    const pdf = new jsPDF();

    // Set background color
    pdf.setFillColor(255, 251, 244); // #FFFBF4 color
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.width,
      pdf.internal.pageSize.height,
      "F"
    );

    // Title
    pdf.setTextColor(14, 79, 205); // Black color for title
    pdf.setFontSize(16);

    pdf.text(
      "DEPARTMENT OF BIOMEDICAL ENGINEERING",
      pdf.internal.pageSize.width / 2,
      15,
      "center"
    );

    // Subtitle
    pdf.setTextColor(14, 79, 205); // Black color for subtitle
    pdf.setFontSize(16);

    pdf.text("PM CHECKLIST", pdf.internal.pageSize.width / 2, 25, "center");

    // Asset details
    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(12);
    pdf.text(`Asset Name:`, 20, 40);
    pdf.setTextColor(0, 0, 0); // Black color for values
    pdf.text(`${asset.asset_name}`, 70, 40);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Serial Number:`, 20, 50);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.serial_no}`, 70, 50);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Model Number:`, 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.model_no}`, 70, 60);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Department:`, 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.department}`, 70, 70);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Done Date:`, 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.pm_done_date}`, 70, 80);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Due Date:`, 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.pm_due_date}`, 70, 90);

    // Add table for parameters
    if (asset.parameter.length > 0) {
      const parameterData = asset.parameter.map((param) => [
        param.name,
        param.status,
      ]);

      pdf.autoTable({
        head: [["Parameter Name", "Status"]],
        body: parameterData,
        startY: 100,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] },
      });
    }

    // Add table for power supply
    if (asset.power_supply.length > 0) {
      const powerSupplyData = asset.power_supply.map((supply) => [
        /* Supply details */
      ]);

      pdf.autoTable({
        head: [["Power Supply Details"]], // Add headers for power supply table
        body: powerSupplyData,
        startY: pdf.autoTable.previous.finalY + 10,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] },
      });
    }

    // Remarks and Done By
    pdf.setTextColor(14, 79, 205);
    pdf.text(`Remarks:`, 20, pdf.autoTable.previous.finalY + 10);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.remarks}`, 70, pdf.autoTable.previous.finalY + 10);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Done By:`, 20, pdf.autoTable.previous.finalY + 20);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.done_by}`, 70, pdf.autoTable.previous.finalY + 20);
    // Save the PDF as a file
    // const pdfBlob = pdf.output("blob");
    // const pdfUrl = URL.createObjectURL(pdfBlob);
    // setPdfUrl(pdfUrl);
    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setUrl(pdfUrl);

    // pdf.save('PMChecklist.pdf');
  };

  const handleSave = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
    (endDate === "" || startDate === "") &&
      setIsDateError("Please fill the field");
    doneBy === "" && setIsDoneByError("Please fill the field");
    remark === "" && setIsRemarkError("Please fill the field");
    doneBy === "" && setIsDoneByError("Please fill the field");
    assetName === "" && setIsAssetNameError("Please fill the field");
    hasEmptyStatus === false &&
      setIsParameterError("Please fill the parameter");

    if (
      isAssetNameError === "" &&
      isDateError === "" &&
      isDoneByError === "" &&
      isModelNumberError === "" &&
      isRemarkError === "" &&
      isSerialNumberError === ""
    ) {
      if (
        assetName !== "" &&
        serialNumber !== "" &&
        modelNumber !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        remark !== "" &&
        doneBy !== "" &&
        hasEmptyStatus === true
      ) {
        let data = {
          userid: userIds?.userid,
          username: userIds?.username,
          hospid: userIds?.hospid,
          hosp_name: userIds?.hospname,
          asset_name: assetName,
          asset_id: assetId,
          asset_code: assetCode,
          brand: brand,
          serial_no: serialNumber,
          model_no: modelNumber,
          department: singleDepartment,
          pm_done_date: formattedStartDate,
          pm_due_date: formattedEndDate,
          remarks: remark,
          done_by: doneBy,
          branchid: userBranch?.id,
          department_id: departmentId,
          parameter: check[0]?.parameter,
          
          power_supply:
            check[0]?.powerSupply === undefined ? [] : check[0]?.powerSupply,
        };

        setIsParameterError("");
        dispatch(postPmCheckList(data, token));
        // setFlag(false);
        // downloadPDF(data)
        setSave(false);
        setOpen(true);
      } else {
        setSnackbarMessage("Please fill in all the required fields.");
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage("Please fill in all the required fields.");
      setSnackbarOpen(true);
    }
  };

  const handleDownloadPdf = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
    (endDate === "" || startDate === "") &&
      setIsDateError("Please fill the field");
    doneBy === "" && setIsDoneByError("Please fill the field");
    remark === "" && setIsRemarkError("Please fill the field");
    doneBy === "" && setIsDoneByError("Please fill the field");
    assetName === "" && setIsAssetNameError("Please fill the field");
    hasEmptyStatus === false &&
      setIsParameterError("Please fill the parameter");

    if (
      isAssetNameError === "" &&
      isDateError === "" &&
      isDoneByError === "" &&
      isModelNumberError === "" &&
      isRemarkError === "" &&
      isSerialNumberError === ""
    ) {
      if (
        assetName !== "" &&
        serialNumber !== "" &&
        modelNumber !== "" &&
        startDate !== "" &&
        endDate !== "" &&
        remark !== "" &&
        doneBy !== "" &&
        hasEmptyStatus === true
      ) {
        let data = {
          userid: userIds?.userid,
          username: userIds?.username,
          hospid: userIds?.hospid,
          hosp_name: userIds?.hospname,
          asset_name: assetName,
          asset_id: assetId,
          asset_code: assetCode,
          brand: brand,
          serial_no: serialNumber,
          model_no: modelNumber,
          department: singleDepartment,
          pm_done_date: startDate,
          pm_due_date: endDate,
          remarks: remark,
          branchid: userBranch?.id,
          done_by: doneBy,
          parameter: check[0]?.parameter,
          power_supply:
            check[0]?.powerSupply === undefined ? [] : check[0]?.powerSupply,
        };

        setIsParameterError("");
        // dispatch(postPmCheckList(data, token));
        // setSave(false)
        // downloadPDF(data)
        handleDownload();
      } else {
        setSnackbarMessage("Please fill in all the required fields.");
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage("Please fill in all the required fields.");
      setSnackbarOpen(true);
    }
  };

  const handleDownload = () => {
    const pdf = new jsPDF();

    // Set background color
    pdf.setFillColor(255, 251, 244); // #FFFBF4 color
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.width,
      pdf.internal.pageSize.height,
      "F"
    );

    // Title
    pdf.setTextColor(14, 79, 205); // Black color for title
    pdf.setFontSize(16);

    pdf.text(
      "DEPARTMENT OF BIOMEDICAL ENGINEERING",
      pdf.internal.pageSize.width / 2,
      15,
      "center"
    );

    // Subtitle
    pdf.setTextColor(14, 79, 205); // Black color for subtitle
    pdf.setFontSize(16);

    pdf.text("PM CHECKLIST", pdf.internal.pageSize.width / 2, 25, "center");

    // Asset details
    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(12);
    pdf.text(`Asset Name:`, 20, 40);
    pdf.setTextColor(0, 0, 0); // Black color for values
    pdf.text(`${iframeData.asset_name}`, 70, 40);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Serial Number:`, 20, 50);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.serial_no}`, 70, 50);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Model Number:`, 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.model_no}`, 70, 60);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Department:`, 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.department}`, 70, 70);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Done Date:`, 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.pm_done_date}`, 70, 80);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Due Date:`, 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.pm_due_date}`, 70, 90);

    // Add table for parameters
    if (iframeData.parameter.length > 0) {
      const parameterData = iframeData.parameter.map((param) => [
        param.name,
        param.status,
      ]);

      pdf.autoTable({
        head: [["Parameter Name", "Status"]],
        body: parameterData,
        startY: 100,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] }, // Set head row color and text color
      });
    }

    // Add table for power supply
    if (iframeData.power_supply.length > 0) {
      const powerSupplyData = iframeData.power_supply.map((supply) => [
        /* Supply details */
      ]);

      pdf.autoTable({
        head: [["Power Supply Details"]], // Add headers for power supply table
        body: powerSupplyData,
        startY: pdf.autoTable.previous.finalY + 10,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] },
      });
    }

    // Remarks and Done By
    pdf.setTextColor(14, 79, 205);
    pdf.text(`Remarks:`, 20, pdf.autoTable.previous.finalY + 10);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.remarks}`, 70, pdf.autoTable.previous.finalY + 10);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Done By:`, 20, pdf.autoTable.previous.finalY + 20);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${iframeData.done_by}`, 70, pdf.autoTable.previous.finalY + 20);
    // Save the PDF as a file
    // const pdfBlob = pdf.output("blob");
    // const pdfUrl = URL.createObjectURL(pdfBlob);
    // setPdfUrl(pdfUrl);
    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    // setUrl(pdfUrl);

    pdf.save("PMChecklist.pdf");
  };

  useEffect(() => {
  
  }, [pdfUrl]);

  const downloadPDF = async (asset) => {
    const pdf = new jsPDF();

    // Set background color
    pdf.setFillColor(255, 251, 244); // #FFFBF4 color
    pdf.rect(
      0,
      0,
      pdf.internal.pageSize.width,
      pdf.internal.pageSize.height,
      "F"
    );

    // Title
    pdf.setTextColor(14, 79, 205); // Black color for title
    pdf.setFontSize(16);

    pdf.text(
      "DEPARTMENT OF BIOMEDICAL ENGINEERING",
      pdf.internal.pageSize.width / 2,
      15,
      "center"
    );

    // Subtitle
    pdf.setTextColor(14, 79, 205); // Black color for subtitle
    pdf.setFontSize(16);

    pdf.text("PM CHECKLIST", pdf.internal.pageSize.width / 2, 25, "center");

    // Asset details
    pdf.setTextColor(14, 79, 205);
    pdf.setFontSize(12);
    pdf.text(`Asset Name:`, 20, 50);
    pdf.setTextColor(0, 0, 0); // Black color for values
    pdf.text(`${asset.asset_name}`, 70, 50);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Serial Number:`, 20, 60);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.serial_no}`, 70, 60);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Model Number:`, 20, 70);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.model_no}`, 70, 70);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Department:`, 20, 80);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.department}`, 70, 80);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Done Date:`, 20, 90);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.pm_done_date}`, 70, 90);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`PM Due Date:`, 20, 100);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.pm_due_date}`, 70, 100);

    // Add table for parameters
    if (asset.parameter.length > 0) {
      const parameterData = asset.parameter.map((param) => [
        param.name,
        param.status,
      ]);

      pdf.autoTable({
        head: [["Parameter Name", "Status"]],
        body: parameterData,
        startY: 110,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] }, // Set head row color and text color
      });
    }

    // Add table for power supply
    if (asset.power_supply.length > 0) {
      const powerSupplyData = asset.power_supply.map((supply) => [
        /* Supply details */
      ]);

      pdf.autoTable({
        head: [["Power Supply Details"]], // Add headers for power supply table
        body: powerSupplyData,
        startY: pdf.autoTable.previous.finalY + 20,
        headStyles: { fillColor: [14, 79, 205], textColor: [255, 255, 255] },
      });
    }

    // Remarks and Done By
    pdf.setTextColor(14, 79, 205);
    pdf.text(`Remarks:`, 20, pdf.autoTable.previous.finalY + 30);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.remarks}`, 70, pdf.autoTable.previous.finalY + 30);

    pdf.setTextColor(14, 79, 205);
    pdf.text(`Done By:`, 20, pdf.autoTable.previous.finalY + 40);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`${asset.done_by}`, 70, pdf.autoTable.previous.finalY + 40);

    pdf.save("PMChecklist.pdf");
  };

  const scrollToDiv = () => {
    const targetDiv = document.getElementById("targetDiv");
    if (targetDiv) {
      targetDiv.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const startTimer = () => {
    setTimeout(scrollToDiv, 800); // 2000 milliseconds = 2 seconds
  };

  return (
    <Wrapper>
      <TopContainers>
        <TitleHead>PM Check List</TitleHead>
      </TopContainers>
      {department?.length !== 0 && department !== undefined ? (
        <>
          <SecondContainer>
            <Box
              sx={{
                borderRadius: "17px",
                borderBottom: "6px solid #1746A2",
                padding: "5px",
                maxWidth: "280px",
              }}>
              <SecondTitle>{params.id} Department.</SecondTitle>
            </Box>

            <SecondSubTitle>
              Kindly select equipment in your department {params.id} .
            </SecondSubTitle>
          </SecondContainer>
          <Main>
            {color && (
              <>
                <SelectContainer>
                  <FormElement sx={{ marginBottom: "10px", marginTop: "5px" }}>
                    <FormTextLabel>Equipment</FormTextLabel>
                    <SpecialDropDowne>
                      <Autocomplete
                        disabled={department?.length <= 0}
                        sx={{ bgcolor: "#EFF5FE" }}
                        value={dropdown}
                        options={department?.equipment?.map(
                          (option) => option.name
                        )}
                        onChange={handleEquipment}
                        renderInput={(params) => (
                          <TextField
                            placeholder="e.g. ECG Machine"
                            {...params}
                            // onClick={handleEquipment}
                          />
                        )}
                      />
                    </SpecialDropDowne>
                    {/* {<HelperText>{"isAssetError"}</HelperText>} */}
                  </FormElement>
                </SelectContainer>

                {check?.length > 0 && (
                  <InnerContainer sx={{ bgcolor: "white" }}>
                    <FormWrapper sx={{ marginBottom: "10px" }}>
                      <FormElement>
                        <FormTextLabel>Asset Name</FormTextLabel>
                        <SpecialDropDowne>
                          <Autocomplete
                            sx={{ bgcolor: "#EFF5FE" }}
                            id="free-solo-demo"
                            onChange={handleAssetName}
                            placeholder="e.g. ventilator"
                            // inputValue={assetName}
                            options={getAllData?.map((option) => ({
                              label: `${option?.name} (${option?.assetcode})`,
                              value: option?.assetid,
                            }))}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Asset Name" />
                            )}
                          />
                        </SpecialDropDowne>
                        {<HelperText>{isAssetNameError}</HelperText>}
                      </FormElement>

                      <FormElement>
                        <FormTextLabel>Serial Number</FormTextLabel>
                        <FormInput
                          sx={{ bgcolor: "#EFF5FE" }}
                          value={serialNumber}
                          onChange={handleSerialNumber}
                          placeholder="e.g. S82-10H"
                        />
                        {<HelperText>{isSerialNumberError}</HelperText>}
                      </FormElement>
                    </FormWrapper>
                    <FormWrapper>
                      <FormElement>
                        <FormTextLabel>Model Number</FormTextLabel>
                        <FormInput
                          sx={{ bgcolor: "#EFF5FE" }}
                          value={modelNumber}
                          onChange={handleModelNumber}
                          placeholder="e.g. MN-10000P27"
                        />
                        {<HelperText>{isModelNumberError}</HelperText>}
                      </FormElement>

                      <FormElement>
                        <FormTextLabel>Brand</FormTextLabel>
                        <FormInput
                          sx={{ bgcolor: "#EFF5FE" }}
                          value={brand}
                          onChange={handleBrand}
                          placeholder="e.g. Jmc"
                        />
                        {<HelperText>{isBrandError}</HelperText>}
                      </FormElement>
                    </FormWrapper>

                    <FormWrapper>
                      <FormElement>
                        <FormTextLabel>Department</FormTextLabel>
                        <FormInput
                          sx={{ bgcolor: "#EFF5FE" }}
                          value={singleDepartment}
                          onChange={handleSingleDepartment}
                          placeholder="e.g. ICU Department"
                        />
                        {/* {<HelperText>{isModelNumberError}</HelperText>} */}
                      </FormElement>

                      <FormElement>
                        <FormTextLabel>Asset Code</FormTextLabel>
                        <FormInput
                          sx={{ bgcolor: "#EFF5FE" }}
                          value={assetCode}
                          onChange={handleAssetCode}
                          placeholder="e.g. AH-10-UUI"
                        />
                        {<HelperText>{setIsAssetCodeError}</HelperText>}
                      </FormElement>
                    </FormWrapper>

                    <FormWrapper sx={{ marginTop: "5px" }}>
                      <FormElement>
                        <FormTextLabel>PM Done Date</FormTextLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div>
                            <FormInput2
                              format="DD/MM/YYYY"
                              sx={{ bgcolor: "#EFF5FE" }}
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                              value={startDate}
                              onChange={hanldeStartDate}
                            />
                          </div>
                        </LocalizationProvider>
                        {<HelperText>{isDateError}</HelperText>}
                      </FormElement>

                      <FormElement>
                        <FormTextLabel>PM Due Date</FormTextLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div>
                            <FormInput2
                              sx={{ bgcolor: "#EFF5FE" }}
                              format="DD/MM/YYYY"
                              value={endDate}
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                              onChange={hanldeEndDate}
                            />
                          </div>
                        </LocalizationProvider>
                        {<HelperText>{isDateError}</HelperText>}
                      </FormElement>
                    </FormWrapper>
                    {check?.length > 0 && (
                      <ParameterWrapper>
                        <SubTitle>Parameters</SubTitle>
                        {check[0]?.parameter?.map((item, index) => (
                          <ParaBox key={index} sx={{ bgcolor: "#EFF5FE" }}>
                            <ContentText>{item?.name}</ContentText>

                            <FormControl
                              sx={{
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: "repeat(3,1fr)",
                              }}>
                              <Button
                                variant={
                                  item.status === "Pass"
                                    ? "contained"
                                    : "outlined"
                                }
                                sx={{
                                  borderRadius: "0px",
                                  bgcolor:
                                    item.status !== "Pass"
                                      ? "white"
                                      : "#1746A2",
                                }}
                                onClick={() =>
                                  handleChangeStatus(index, "Pass")
                                }>
                                Pass
                              </Button>
                              <Button
                                sx={{
                                  borderRadius: "0px",
                                  bgcolor:
                                    item.status !== "Fail"
                                      ? "white"
                                      : "#1746A2",
                                }}
                                variant={
                                  item.status === "Fail"
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() =>
                                  handleChangeStatus(index, "Fail")
                                }>
                                Fail
                              </Button>
                              <Button
                                sx={{
                                  borderRadius: "0px",
                                  bgcolor:
                                    item.status !== "N/A" ? "white" : "#1746A2",
                                }}
                                variant={
                                  item.status === "N/A"
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() =>
                                  handleChangeStatus(index, "N/A")
                                }>
                                N/A
                              </Button>
                            </FormControl>
                          </ParaBox>
                        ))}
                        {check[0]?.powerSupply !== undefined && (
                          <>
                            <SubTitle>Power Supply</SubTitle>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}>
                              <ItemText
                                sx={{
                                  textTransform: "none",
                                  color: "#1746A2",
                                }}>
                                Parameter
                              </ItemText>
                              <ItemText
                                sx={{
                                  textTransform: "none",
                                  color: "#1746A2",
                                }}>
                                Selected Value
                              </ItemText>

                              <RadioContainer sx={{ width: "40%" }}>
                                <ItemText
                                  sx={{
                                    width: "100%",
                                    textTransform: "none",
                                    color: "#1746A2",
                                  }}>
                                  Observed Value
                                </ItemText>
                              </RadioContainer>
                            </Box>
                          </>
                        )}
                        {check[0]?.powerSupply !== undefined &&
                          check[0]?.powerSupply?.map((item, index) => (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}>
                              <ItemText>{item?.supplyParameter}</ItemText>
                              <ItemText>{item?.selectedValue}</ItemText>

                              <RadioContainer sx={{ width: "40%" }}>
                                <OutlinedInput
                                  onChange={(e) =>
                                    handleChangeObservedValue(e, index)
                                  }
                                  value={item.observerdValue}
                                  placeholder="value..."
                                />
                              </RadioContainer>
                            </Box>
                          ))}
                      </ParameterWrapper>
                    )}

                    <FormWrapper sx={{ marginTop: "5px" }}>
                      <FormElement>
                        <FormTextLabel>Remark</FormTextLabel>
                        <FormInput
                          sx={{ bgcolor: "#EFF5FE" }}
                          placeholder="e.g. All updated."
                          value={remark}
                          onChange={handleRemark}
                        />
                        {<HelperText>{isRemarkError}</HelperText>}
                      </FormElement>

                      <FormElement>
                        <FormTextLabel>Done By</FormTextLabel>
                        <FormInput
                          sx={{ bgcolor: "#EFF5FE" }}
                          onChange={handleDoneBy}
                          value={doneBy}
                          placeholder="e.g. Joseph"
                        />
                        {<HelperText>{isDoneByError}</HelperText>}
                      </FormElement>
                    </FormWrapper>
                    {<HelperText>{isParameterError}</HelperText>}
                    <ButtonBox>
                      <SaveButton
                        variant="outlined"
                        sx={{
                          border: "2px solid #1746A2",
                          color: "#1746A2",
                          bgcolor: "white",
                        }}
                        onClick={() => navigate(-1)}>
                        Cancel
                      </SaveButton>

                      <SaveButton
                        // id="targetDiv"
                        disabled={userAccess?.role === "Guest"}
                        variant="contained"
                        sx={{
                          bgcolor: "#1746A2",
                          color: "white",
                        }}
                        onClick={handleSubmit}>
                        Submit
                      </SaveButton>

                  
                    </ButtonBox>
                  </InnerContainer>
                )}
              </>
            )}
          </Main>
        </>
      ) : (
        <>No department found</>
      )}

      {flag && (
        <>
          <FrameBox
            id="targetDiv"
            sx={{
              // border: "1px solid red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
         

            <div
              style={{
                marginTop: 20,
                textAlign: "center",
                width: "100%",
                height: "600px",
                maxWidth: "900px",
                
              }}>
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                <Viewer fileUrl={url} />
              </Worker>
            </div>
          </FrameBox>

          <ButtonBox sx={{ paddingBottom: "20px" }}>
            <PdfButton
              disabled={userAccess?.role === "Guest"}
              variant="contained"
              sx={{
                bgcolor: "#F78117",
                color: "white",
                "&:hover": {
                  transform: "scale3d(1.05, 1.05, 2)",
                  bgcolor: "#F78117",
                  cursor: "pointer",
                  color: "white",
                },
              }}
              onClick={handleDownloadPdf}>
              Download
            </PdfButton>

            {save && (
              <PdfButton
                variant="contained"
                onClick={handleSave}
                sx={{
                  bgcolor: "#F78117",
                  color: "white",
                  "&:hover": {
                    transform: "scale3d(1.05, 1.05, 2)",
                    bgcolor: "#F78117",
                    cursor: "pointer",
                    color: "white",
                  },
                }}
                // endIcon={
                //   <DownloadOutlinedIcon sx={{ color: "#F78117" }} />
                // }
              >
                Save
              </PdfButton>
            )}
          </ButtonBox>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* <ShowPdf asset={iframeData}  /> */}
      <SuccessModal
        openModal={open}
        handleCloseModal={handleCloseModal}
        response={pmChecklistData?.success}
        text={"Pm checklist submitted"}
        toggle={open}
      />
    </Wrapper>
  );
}

export default PmCheckList;
