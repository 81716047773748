import {
  AppBar,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import React, { useEffect } from "react";
import logo from "../images/logo 1.png";
import { useState } from "react";
import DrowerComp from "./DrowerComp";
import { Link, useLocation, useNavigate } from "react-router-dom";

import styled from "@emotion/styled";

function Navbar3({ handleScrollTo, scrollTop }) {
  const [count, setCount] = useState(0);
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const ImageBox = styled(Box)(({ theme }) => ({
    marginRight: "50px",
    marginLeft: "20px",
    cursor: "pointer",
    width: "109.64px",
    height: "64px",

    [theme.breakpoints.down("xl")]: {
      width: "70px",
      height: "40px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "70px",
      height: "40px",
    },
    [theme.breakpoints.down("md")]: {
      width: "60px",
      height: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60px",
      height: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60px",
      height: "30px",
    },
  }));

  const SignInUp = styled(Button)(({ theme }) => ({
    bgcolor: "#1746A2",
    color: "white",
    fontSize: "18px",
    borderRadius: "30px",
    width: "149px",
    height: "39px",
    textTransform: "none",
    "&:hover": {
      transform: "scale3d(1.05, 1.05, 2)",
      cursor: "pointer",
      color: "white",
      bgcolor: "#F78117",
    },

    [theme.breakpoints.down("xl")]: {
      fontSize: "16px",
      width: "100px",
      height: "35px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100px",
      height: "33px",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},

    [theme.breakpoints.down("xs")]: {},
  }));

  const handleSignin = () => {
    navigate("/signin");
  };



  useEffect(() => {
    if (count === 1) {
      setTimeout(handleCall, 500);
    }
  }, [count]);

  const handleCall = () => {
    handleScrollTo();
  };

  return (
    <>
      <AppBar sx={{}}>
        <Toolbar
          style={{ color: "#F78117" }}
          sx={{
            background: "#f0f8ff",
            fontFamily: "Poppins",

            height: {
              xl: "108px",
              lg: "65px",
              md: "80px",
              sm: "60px",
              xs: "60px",
            },
          }}>
          {isMatch ? (
            <>
              <Box
                as="img"
                component={Link}
                to="/"
                sx={{
                  width: "44px",
                  height: "25.64px",
                }}
                src={logo}
                onClick={() => navigate("/")}
                alt="logo"
              />
              <DrowerComp handleScrollTo={handleScrollTo} />
            </>
          ) : (
            <>
              <ImageBox
                as="img"
                src={logo}
                alt="logo"
                onClick={() => navigate("/")}
              />
              <Tabs
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#F78117",
                  },
                }}
                style={{ color: "#F78117" }}
                value={count}
                onChange={(e, value) => setCount(value)}
                textColor="primary">
                <Tab
                  component={Link}
                  to="/"
                  style={{
                    color: "#1746A2",
                    fontSize: "18px",
                    textTransform: "none",
                    fontWeight: "600",
                  }}
                  label="Home"
                  onClick={scrollTop}
                />

                {path === "/" && (
                  <Tab
                    style={{
                      color: "#1746A2",
                      fontSize: "18px",
                      textTransform: "none",
                      fontWeight: "600",
                    }}
                    label="How Vajra Works"
                    onClick={handleScrollTo}
                  />
                )}
              </Tabs>
              <SignInUp
                fontFamily="Poppins"
                sx={{
                  marginLeft: "auto",
                  bgcolor: "#1746A2",
                  color: "white",
                  borderRadius: "30px",

                  textTransform: "none",
                  "&:hover": {
                    transform: "scale3d(1.05, 1.05, 2)",
                    cursor: "pointer",
                    color: "white",
                    bgcolor: "#F78117",
                  },
                }}
                onClick={handleSignin}>
                Sign in
              </SignInUp>
              <SignInUp
                fontFamily="Poppins"
                sx={{
                  marginLeft: "20px",
                  bgcolor: "#1746A2",
                  color: "white",
                  "&:hover": {
                    transform: "scale3d(1.05, 1.05, 2)",
                    cursor: "pointer",
                    color: "white",
                    bgcolor: "#F78117",
                  },
                }}
                onClick={() => navigate("/signup")}>
                Sign up
              </SignInUp>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar3;
