import * as React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function DialogBox({ openModal, handleCloseModal }) {
  const navigate = useNavigate();

  return (
    <div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle sx={{ textAlign: "center", bgcolor: "#1746A2" }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: "white",
              marginTop: "2%",
              "&:hover": {
                backgroundColor: "#FFF",
              },
            }}
            onClick={() => navigate("/")}>
            <Typography
              variant="h6"
              color="white"
              component="div"
              sx={{ textTransform: "none", color: "#1746A2" }}>
              Upgrade to Premium
            </Typography>
          </Button>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "100%",
            textAlign: "center",
            bgcolor: "#1746A2",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <DialogContentText sx={{ color: "white", width: "85%" }}>
            To access this feature, please upgrade to the Premium plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#1746A2" }}>
          <Button onClick={handleCloseModal} sx={{ color: "white" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
